<template>
  <header >
        <div class="logoArea">
            <img alt="school logo" :src="schoolLogoFilePath"  class="logo" v-if="showHeader">
        </div>
        <div v-if="wasUpdated">
            <p class="update">Updates are available for your application. Please click Refresh to download the latest version.</p>
            <button @click="refreshapp()">Refresh</button>
        </div>
        <div class="navArea" v-if="loggedIn && loggedInUser.isAdmin">
            <button :class="navButtonClass('home')" @click="selectNav('home')">
                Home
            </button>
            <button :class="navButtonClass('report')" @click="selectNav('report')">
                Reports
            </button>
            <button :class="navButtonClass('admin')" @click="selectNav('admin')">
                Admin
            </button>
        </div>  
        
        <div class="userArea vertFlex rightjustified" v-if="loggedIn && showHeader">
         
            <p class="userlabel bold">{{loggedInUser.name}}</p>     
            <linkbutton @click="logout()" toplabel="Logout" />
            <!-- <updatepassword :isAdminChangePassword="false"   :username="username" /> --> 
            <p v-if="!online">OFFLINE</p>
            
        </div>
        
    </header>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import linkbutton from './controls/linkbutton.vue'
// import updatepassword from './actions/updatepassword.vue'
import datamethods from '../services/datamethods'
import adhelper from '../services/adhelper'
export default {
    name: "navheader",
    components:{
        linkbutton,

    },
    mixins:[datamethods, adhelper],
    data() {
        return {
            // schoolLogoFilePath:'./../' + window.SCHOOL_LOGO,
            schoolname:window.SCHOOL_NAME,
        };
    },
    computed:{
        ...mapFields([
            'currentNav',
            'loggedIn',
            'loggedInUser',
            'lastRefreshDateTime',
            'username',
            'oldpassword',
            'logoOverride',
            'imageBasePath',
            'showHeader',
            'online',
            'filter',
            'wasUpdated'
        ]),
        schoolLogoFilePath(){
            
                return './../' + window.SCHOOL_LOGO
            

        },
        showOpenClose(){
            if(window.SHOW_OPEN_CLOSE==undefined){return true}
            else{return window.SHOW_OPEN_CLOSE}
        }
    },
    methods:{
        refreshapp(){
            location.reload(true);
        },
        selectNav(name){
            this.currentNav=name;
        },
        navButtonClass(name){
            if(this.currentNav==name){
                return "selectedNav"
            }else{
                return "unselectedNav"
            }
        },
        logout(){
            if(window.USE_AD_SECURITY){this.logoutmsal();}
            this.loggedIn=false;
            this.filter.locationId=-1
            
            
        
        },
        /* async getImageBasePath(){
            //call api
            var response = await this.callAPI_Image(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        }, */
    },
    mounted(){
        //this.getImageBasePath();
    }
}
</script>

<style>
header{

    padding:10px 20px 10px 10px;
    display:grid;
    grid-template-columns:2fr 4fr 2fr;
    grid-template-rows:auto;
    grid-template-areas: 
        "logo nav user";
    font-size:medium;
    background-color:var(--header-color);
 
}
.logo{
    max-width: 100%;
    max-height:75px;
}
.navArea{
    padding:0px;
    margin:0px;
}
.selectedNav{
    color:var(--light-text-color);
    background-color:var(--button-color);
    border: 4px solid var(--highlight-button-color);
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
     margin:10px;

}
.unselectedNav{
    color:var(--light-text-color);
    background-color:var(--button-color);
    border: none;
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
}
.schoolname{
    font-size:large;
    text-align: center;
    margin:0px 0px 0px 20px;
}
.userlabel{
   
    margin:0px 0px 10px 0px;
    
}
.userArea{
  grid-area:user;
}
.modeImage{
    height:25px;
    margin:5px;
}
.buttoninside{
  display:flex;
  flex-direction: column;
  margin:0px;
    padding:0px;
}
.buttonlabel{
    margin:0px;
}

</style>