<template>
    <div class="statusDiv" id="printableArea">
        
        <div class="vertFlex">
            <p class="studentname larger leftjustified">Status</p>
            <div class=" horizFlex">
                <div class="vertFlex">
                    <label class="entryLabel" for="">Select Location</label>
                    <optionbutton :itemList="locationList" @select="selectLocation"  :initialSelectedId="locationId"/>
                </div>
                <div class="vertFlex">

                    <label class="entryLabel" for="">Select Training</label>
                    <optionbutton ref="loclist" :itemList="procedureList" @select="selectprocedure"  v-if="procedureList.length>0"/>
                </div>
                <div class="vertFlex">
                    <div class="horizFlex datecheckdiv">
                        <input class="datecheck" type="checkbox" v-model="useHireDate" @change="setUseHireDate()">
                        <label class="datelabel"> Start Date</label>
                        
                        
                    </div>
                    <div class="horizFlex datediv">
                        <input type="date" :disabled="!useHireDate" v-model="limitToHireDate" class="dateinput"/>
                    </div>
                </div>
                <smallbutton toplabel="Go" @click="getStatus()" buttonclass="buttonprimary gobutton"/>
                
            </div>
            <p class="pleasewait leftjustified" v-if="pleaseWait">Please Wait</p>
            <div class="horizFlex">
                <smallbutton toplabel="Print" @click="print('printableArea')" buttonclass="buttonprimary innerbuttonwide" v-if="selectedInstance.IsLocked"/>
                <smallbutton toplabel="Close" @click="home" buttonclass="buttonprimary innerbuttonwide" />

            </div>

        </div>
        
        <div class="leftjustified">
            <p class="responsemessage" v-if="responseMessage!=null">{{ responseMessage }}</p>
        </div>
        
        <div class="gradesteps" v-if="selectedInstanceList.length>0">
            <div class="horizFlexStretch">
                
               
            </div>
            
            <!-- StudentsAsColumns -->
           <div v-if="!BatchComponentsAsColumns">
                <!-- headers -->
                <div class="horizFlex " >
                    <p class="fixedcolstuwide"></p>
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="headerdivstatus center">
                        <p class="fixedcolstu">{{ h.StudentName}}</p>
                        <div class="vertFlex" v-if="showProficiencyForThisEval && ShowProficiency && canAccess('Set Proficiency')">
                            <confirm :buttonclass="proficiencyClass(h)" :showButton="paramTrue" body="Do you want to update the proficiency?" :title="proficiencyLabelFull(h)"
                                    @yes="toggleProficient(h)" :buttonLabel="proficiencyLabel(h)" />
                        </div>
                    </div>
                </div>
            
                <!-- steps and components -->
                <div class="scrolldiv">
                    <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="step" >
                        <div > 
                            <p class="steplabel">{{s.Name}}</p>
                        </div>
                        
                        <div :class="componentclass">
                            <div v-for="c in s.StepComponentList" :key="c.ID" :value="c.ID" class="component ">
                                <div v-if="c.IsActive">
                                    <div class="horizFlex" >
                                        <p class="componentlabel fixedcolstuwide">{{c.Name}}</p>
                                        <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="center fixedcolstu">
                                            <button  @click="grademe(c.ID,h)" :class="gradebuttonclass(c.ID,h)" :disabled="true">
                                                <div>
                                                    <p class="gradeboxlabel" v-if="!usingChecks">{{ getGradelast(c.ID,h) }}</p>
                                                    <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                                </div>
                                            
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>              
                        </div>
                    </div>
                </div>
                <!-- totals -->
                <div class="horizFlex " >
                     <p class="bold left10 fixedcolstuwide ">{{totallabel}}</p>
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="center" >
                        <p class="bold fixedcolstu totallabel ">{{ total2(h)}}</p>
                    </div> 
                </div>
           </div>

           <!-- ComponentsAsColumns -->
           <div v-if="BatchComponentsAsColumns">
                <div class="scrolldivbatch">
                <!-- headers -->
                <div class="horizFlex" >
                    <p class="fixedcolumnlabelwide"></p>
                    <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="horizFlex " >
                        <div v-for="c in filteredComponents" :key="c.ID" :value="c.ID" class="component">
                           
                                <p class="fixedcolumnlabel">{{abbrevComp(c.Name)}}</p>
                            
                           
                        </div>
                    </div>
                    <p class="bold left10">{{totallabel}}</p>
                </div>
                
                <!-- steps and components -->
                
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" >
                        <div class="horizFlex " >
                            <p class="fixedcolumnlabelwide">{{h.StudentName}}</p>
                            
                            <div class=" horizFlex">
                                <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="horizFlex" >
                                    <div v-for="c in filteredComponents" :key="c.ID" :value="c.ID" class="fixedcolumnlabel">
                                       
                                            <button  @click="grademe(c.ID,h)" :class="gradebuttonclass(c.ID,h)" :disabled="!isGradable(c.ID,h)">
                                                <div>
                                                    <p class="gradeboxlabel" v-if="!usingChecks">{{ getGrade(c.ID,h) }}</p>
                                                    <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                                </div>
                                            
                                            </button>
                                        
                                       
                                    </div>  
                                </div> 

                                <!-- totals -->
                                <div class="headerrow" >
                                    
                                    <p class="bold">{{ total(h)}}</p>
                                    
                                </div>
                            </div>
                                    
                        </div>
                    </div>
                
                </div>
                <!-- totals -->
                <!-- <div class="headerrow" >
                    <p class="bold left10">{{totallabel}}</p>
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" >
                        <p class="bold">{{ total(h)}}</p>
                    </div>
                </div> -->
           </div>
            
  
            
        </div>
        <grade15 ref="grade15" @select="setGrade" :gradeType="selectedGradeType"/>
        <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
        <components ref="components" @save="savecomponents()"/>
        <confirm ref="confirmprof" :showButton="paramFalse" :body="confirmProficiencyText" :title="confirmProficiencyTitle"
        @yes="toggleProficientFlag(evalToConfirm,true)" :redText="paramTrue"  />
    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import grade15 from './grade15.vue'
  import components from './components.vue'

  import modalmessage from '../controls/modalmessage.vue'
    import optionbutton from '../controls/optionbutton.vue'
  import confirm from '../actions/confirm.vue'
    import evalhelper from '../../services/evalhelper.js'
    import securityhelper from '../../services/securityhelper.js'
  export default {
      name:'status',
      mixins:[datamethods, format,evalhelper,securityhelper],
      components:{
          smallbutton,
          linkbutton,
          grade15,
          modalmessage,
          confirm,
          components,
          optionbutton
      },
      data(){
          return{
            hiddenComponentId:0,
            procedureList:[],
            locationId:0,
            procedureId:0,

            zoom:1,
            showAllComponents:true,
            selectedComponents:[],

            selectedComponent:0,
            
            

            paramTrue:true,
            paramFalse:false,   
            listenersConnected:true,
            
           
            proficient:false,
            calcTotal:0,
            multColumnOverride:-1,

            useHireDate:false,
            limitToHireDate:null
          }
      },
      computed:{
       
        filterlabel(){
            if(this.showAllComponents){return "Show Filtered"}
            else{return "Show All"}
        },
        selectedInstanceComment(){
            return this.selectedInstance.OverallGradeList_Instructor[0].Comment
        },
        checkImage(){
            return require("@/assets/check.png")
        },
        totallabel(){
            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return 'Passed: ' 
            }else if(this.selectedGradeSheet.showTotalAsSum){
               
                return 'Total: ' 
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                
                return 'Avg: '
            }else if(this.selectedGradeSheet.showTotalOutOf100){
             
                return 'Out of 100: '
            }else{
                
            }
        },
        
        selectedGradeType(){
            if (this.selectedGradeSheet.gradeType==null){
                return '1to5'
            }else{return this.selectedGradeSheet.gradeType}
                
        },
        multiColumnCount(){
            var cols=4
            if(window.DEFAULT_COLUMN_COUNT!=undefined){cols=window.DEFAULT_COLUMN_COUNT}

            if(this.multColumnOverride>0){cols = this.multColumnOverride}
            return cols
        },
        
        BatchComponentsAsColumns(){
            if(this.selectedGradeSheet.batch_ComponentsAsColumns!=undefined){return this.selectedGradeSheet.batch_ComponentsAsColumns}
            if(window.BATCH_COMPONENTS_AS_COLUMNS==undefined){return false}
            else{return window.BATCH_COMPONENTS_AS_COLUMNS}
        },
        ShowSignature(){
            if(window.SHOW_SIGNATURE==undefined){return false}
            else{return window.SHOW_SIGNATURE}
        },
        RequireManualEntry(){
            if(window.REQUIRE_MANUAL_ENTRY==undefined){return false}
            else{return window.REQUIRE_MANUAL_ENTRY}
        },
        ShowStudentImage(){
            if(window.SHOW_STUDENT_IMAGE==undefined){return false}
            else{return window.SHOW_STUDENT_IMAGE}
        },
        
     
        
        componentclass(){
            return "componentdiv zoom" + this.zoom
        },
       
          //image
          imagePathPerson(){
            return this.selectedInstance.studentImageFileName
              //var path = this.employeecurrentPerson.personImageFileName;
              //if(path==''){return}
              //return path
              /* console.log(this.imageBasePath + path)
              return this.imageBasePath + path; */
          },
          usingChecks(){
            return this.selectedGradeType=='check'
        },
        usingPassFail(){
            return this.selectedGradeType=='pf'
        },
          ...mapFields([
              'loggedInUser',
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'selectedGradeSheet',
             'selectedInstance',
             'selectedInstanceList',
             'unchangedInstance',
             'online',
             'responseMessage',
             'locationList',
             'confirmProficiencyText',
            'confirmProficiencyTitle',
            'evalToConfirm',
            
          ]),
          filteredComponents(){
            
            var filt=[]
            var hidid = 0
            if(this.showAllComponents){
                this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        if(comp.IsActive){
                            filt.push(comp)
                        
                        }else{
                            hidid = comp.ID
                        }
                    }
                    this.hiddenComponentId=hidid
                return filt
            }
            var scope = this


            //console.log(first + ' ' + last)
            this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.selected && comp.IsActive){
                    filt.push(comp)
                }else{
                    hidid = comp.ID
                }
            }
            this.hiddenComponentId=hidid
            return filt
        },
        
      },
      props:{
          
          
          
      },
      methods:{
        start(){
            this.selectedInstance={}
            this.selectedInstanceList=[]
            this.locationId=this.loggedInUser.LocationId
            this.selectLocation(this.locationId);
            this.getProcedures()
            //console.log('start status')
        },
        proficiencyClass(i){
            if(i.StudentIsProficient_ThisItem==true){
                return "buttongreen innerbuttonprof"
            }else{return "buttonred innerbuttonprof"}
        },
        proficiencyLabel(i){
            var lbl
            if(i.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}

            return lbl
        },
        proficiencyLabelFull(i){
            var lbl
            if(i.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}

            if(i.Name==null){

            }else if (i.Name.toLowerCase().includes('range')){
                lbl=lbl + ' on Range'
            }else if (i.Name.toLowerCase().includes('road')){
                lbl=lbl + ' on Road'
            }
            return lbl
        },
        setUseHireDate(){
           // console.log('setnullhiredate')
            if(!this.useHireDate){
                this.limitToHireDate=null
            }
        },
        async getStatus(){
            if(this.locationId==0 || this.procedureId==0){return}
        
          //  console.log('get status')
            let req = {
                locationId:this.locationId,
                procedureSequenceId:this.procedureId,
                startDate:this.limitToHireDate
            }
            if(!this.useHireDate){req.startDate=null}
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance_batch_seq')
            //handle response
            if(response.Successful){
                 //this.selectedGradeSheet = response.ProcedureSequence
                 //***get sequence! */
                this.selectedGradeSheet=this.GetSequence(this.procedureId)
                 this.selectedInstanceList = response.ProcedureInstanceList
                 if(this.selectedInstanceList.length>0){
                    this.selectedInstance = this.selectedInstanceList[0]
                 }else{
                    this.selectedInstance={}
                 }
                
                
               
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getProcedures(){

            let req = {
                id:-1,
            }
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedures') 
            //procedures_batch
            //handle response
            if(response.Successful){
                this.procedureList = response.ProcedureList
                if(this.procedureList.length>0){
                   // this.procedureId=this.procedureList[0].id
                    
                    
                }
                this.$nextTick(()=>{
                    //this.$refs.loclist.selectbyid(this.locationId)
                });
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
            selectLocation(id){
                this.locationId=id
                //this.getStatus()
            },
            
            selectprocedure(id){
                this.procedureId=id
                this.getSingleSequenceForCache(id)
                //this.getStatus()
            },
        savecomponents(){
            this.showAllComponents=false

        },
       /*  setcols(f,l){
            this.firstcol=f
            this.lastcol=l
        }, */
        
        abbrevComp(name){
            return name.replace('Chapter','Ch').replace('Score','')
        },
        total(inst){
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = -1;
            var hidid = this.hiddenComponentId
            /* inst.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
               
                    if(comp.Grade!=null && comp.RelatedID!=hidid ){
                        
                        total = total + Number(comp.Grade)
                        count = count + 1
                        console.log(inst.StudentName +  'c ' + count +  ' v '  + comp.Grade + ' t ' + total)
                    }
                    if(comp.Grade=='P'){
                        passcount = passcount + 1
                        
                    }
                    pfcount=pfcount + 1
                
                
            } */
            inst.instanceHistory.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    
                        if(comp.Grade!=null  && comp.RelatedID!=hidid){
                            total = total + Number(comp.Grade)
                            count = count + 1
                            console.log(inst.StudentName + 'c ' + count +  ' v '  + comp.Grade + ' t ' + total + ' ' + comp.RelatedID)
                        }
                        if(comp.Grade=='P'){
                            passcount = passcount + 1
                            
                        }
                        pfcount=pfcount + 1
                    
                }
            }

            avg = total/count;
         //   console.log('c ' + count + ' t ' + total + 'avg ' + avg)
            outof100=100-total;

           // console.log(avg)
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return passcount + ' out of ' + pfcount;
            }else if(this.selectedGradeSheet.showTotalAsSum){
                this.calcTotal = total
                return  total;
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                this.calcTotal = avg.toFixed(2);
                return  avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalOutOf100){
                this.calcTotal=outof100multiColumnCount
                return  outof100;
            }else{
                this.calcTotal=total
                return total
            }

        },
        total2(inst){
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = 0;
            var hidid = this.hiddenComponentId
            var mythis = this
            this.selectedGradeSheet.ProcedureStepList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp = arr[index]
                    comp.StepComponentList.forEach(myFunction3)
                        function myFunction3(item, index, arr) {
                            let comp2 = arr[index]
                            var grade = mythis.getGradelast(comp2.ID, inst)
                            console.log(comp2.ID + ' ' + inst.ID  + grade)
                            if(grade && comp2.RelatedID!=hidid ){
                                if(grade != 'NA' && grade !='IA'){
                                    total = total + Number(grade)
                                    count = count + 1
                                }
                                
                            }else if(grade==null && comp2.RelatedID!=hidid){
                                    count =  count + 1
                            }
                            if(grade=='P' || grade=='1'){
                                passcount = passcount + 1
                                
                            }
                            pfcount=pfcount + 1
                        }
                }

           
     

            avg = total/count;
         //   console.log('c ' + count + ' t ' + total + 'avg ' + avg)
            outof100=100-total;

           // console.log(avg)
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return passcount + ' out of ' + count;
            }else if(this.selectedGradeSheet.showTotalAsSum){
                this.calcTotal = total
                return  total;
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                this.calcTotal = avg.toFixed(2);
                return  avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalOutOf100){
                this.calcTotal=outof100multiColumnCount
                return  outof100;
            }else{
                this.calcTotal=total
                return total
            }

        },
        formatDateTime(dt){
            return this.$options.filters.formatDateTime_mini(dt)
        },
        columns(){
            var mult = this.multiColumnCount
            if(this.zoom==mult){
                this.zoom=1
            }else{
                this.zoom=mult
            }
      
        },
         print(divName){
          //  console.log('print')
            window.print();
            /* var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents; */
        },
        addComment(){
            if(!this.online){this.handleOffline(); return}
            this.$refs.addcomment.Update();

        },
        
        
        
        async toggleProficient(i){
            if(!this.online){this.handleOffline('prof'); return}

            var success=await this.toggleProficientFlag(i,true ) //set flag that we already checked...no need to
            if(!success){
                this.$refs.confirmprof.Show()
            }else{

            }

            /* if(!this.online){this.handleOffline(); return}
            let req = {
                ProcedureInstance:i,
                loggedInUser:this.loggedInUser.user_personId

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'toggleproficiency')
            //handle response
            if(response.Successful){
                i.StudentIsProficient_ThisItem=!i.StudentIsProficient_ThisItem
                    
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  */
            
        },
        openSignModal(docname,docid,doctypename){
            if(!this.online){this.handleOffline(); return}
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },
        grademe(componentId, instance){
            this.selectedComponent=componentId
            this.selectedInstance=instance

            if(this.selectedGradeType=='check'){
                var g = this.getGrade(componentId,instance)
                if(g=='P'){
                    this.setGrade(null)
                }else{
                    this.setGrade('P')
                }
            }else{
                this.$refs.grade15.Update();
            }
           

           
           // console.log(this.selectedGradeType)
            
        },



        setGrade(grade){
          //  console.log('setgrade ' + grade)
            var componentId=this.selectedComponent
           // console.log(componentId)

            this.selectedInstance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    comp.Grade=grade
                    comp.IsDirty=true //mark dirty
                }
            }

            //save to cached instance
           // this.saveInstanceToCache()
        },
        isGradable(componentId,instance){
            if(this.selectedInstance.IsLocked){return false}
           // console.log('gradable ' + instance.ID + ' ' + componentId)
            //return instance.ID==this.selectedInstance.ID && this.selectedInstance.IsLocked==false
            var isgradable = true
            instance.instanceHistory.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            isgradable=!comp.IsLocked
                        }
                        
                        
                    }
                }
            }
            return isgradable
        },
        gradebuttonclass(componentId,instance){
            return "gradebuttonlocked"
            /* if(this.isGradable(componentId,instance)){
                return "gradebutton"
            }else{
                return "gradebuttonlocked"
            } */
        },
        
        showCheck(componentId, instance){
            var show = false;
            if(this.usingChecks){
                var g = this.getGrade(componentId,instance)
                //console.log('show ' + componentId + ' ' + g)
                show = (g =='1' || g=='P')
                //console.log(show)
            }
            
           return  show
        },
        getGrade(componentId, instance){
            //console.log('get grade ' + instance.ID + " " + instance.ProcedureStepComponentGrade_InstructorList.length)
            var grade;

            instance.instanceHistory.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            grade=comp.Grade
                        }
                        
                    }
                }
            }

            //if we didn't find it in history, look in current
            if(grade==null){
                instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            grade=comp.Grade
                        }
                        
                    }
                }
            }
            
            
            //console.log('got it ' + componentId + ' ' + grade)
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
            if(grade!=null){
                if(grade.includes('.')){
                    grade = Number(grade).toFixed(0)
                }
            }
            

            return grade
        },
        getGradelast(componentId, instance){
            
            //console.log('get grade ' + instance.ID + " " + instance.ProcedureStepComponentGrade_InstructorList.length)
            var grade=null;

            //first look in current
            if(instance.ProcedureStepComponentGrade_InstructorList!=null)     {
                instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            grade=comp.Grade
                        }
                        
                    }
                }
            }
           
            
            //if we didn't have it, go looking for the latest one
            if(grade==null){
                var gradedate=null
                if(instance.instanceHistory!=null){
                    instance.instanceHistory.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        let comp=arr[index]
                        comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                        function myFunction2(item, index, arr) {
                            let comp=arr[index]
                            if(comp.RelatedID==componentId){
                                //if this grade isn't null
                                if(comp.Grade!=null){
                                    //if we don't have a date to compare to, then use it
                                    if(gradedate==null){
                                        grade=comp.Grade
                                        gradedate=comp.gradeDateTime
                                    //if we are LATER than the the date we last used, then use this one    
                                    }else if(comp.gradeDateTime > gradedate){
                                        grade=comp.Grade
                                        gradedate=comp.gradeDateTime
                                    }
                                    
                                }
                                
                            }
                        }
                    }
                }
                
            }
            

            
            
            
            //console.log('got it ' + componentId + ' ' + grade)
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
            if(grade!=null){
                if(grade.includes('.')){
                    grade = Number(grade).toFixed(0)
                }
            }
            

            return grade
        },
        
        
        
          async home(){

            this.$emit('gotohome')
            
          },
          cancelGrading(){
            this.saveTimeDataToStorage()
            this.$emit('gotohome')
          },
          
         //offline
         handleOffline(){
            this.$refs.modalmessage.show()
                console.log('offline... no soup for you')
        },


        //TIME DATA
        startGrade(){
            this.getInstanceTimeDataFromStorage()

            //we are going into grade mode. place to mark time if needed
            if(this.isManualTime){
                //MANUAL
                
            }else{
                //AUTO
                this.selectedTimeData.autoTimeItems.push(this.getNewAutoTimeItem())
            }
        },
       
        getInstanceTimeDataFromStorage(){
            var instId = this.selectedInstance.ID

            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData
            
            if(tmptime==undefined){
                this.timeData=[this.getNewTimeData()] //get it and add to the list
                this.selectedTimeData =  this.timeData[0] //set it as selected
                return
            }
            this.timeData = JSON.parse(tmptime);
            var foundTimeData=null
            this.timeData.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.instanceId==instId){
                    foundTimeData=comp
                }
            }
            //didn't find it, so we need to make/add a new one
            if(foundTimeData==null){
                var newTimeData = this.getNewTimeData()
                this.timeData.push(newTimeData)
                this.selectedTimeData = newTimeData
            }else{
                //found it!
                this.selectedTimeData = foundTimeData
            }
        },
        removeTimeDataForSubmittedInstance(instId){
            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData
            
           
            this.timeData = JSON.parse(tmptime);
            var foundTimeData=null
            var itemindex
            this.timeData.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.instanceId==instId){
                    foundTimeData=comp
                    itemindex = index
                }
            }

            //remove
            this.timeData.splice(itemindex,1)

            //save
            this.saveTimeDataToStorage()
        },
        saveTimeDataToStorage(){
            localStorage.timeData=JSON.stringify(this.timeData)
        },
        getNewTimeData(){
            var time = {
                instanceId:this.selectedInstance.ID,
                enteredCourseDate:this.$options.filters.formatDateForInputToday(),
                enteredCourseStartTime:null,
                enteredCourseEndTime:null,
                enteredBTWMinutes:0,
                autoTimeItems:[]

            }
            return time
        },
        getNewAutoTimeItem(){
            var item={
                startDateTime:this.$options.filters.formatDateTime_Now(),
                endDateTime:null
            }
            return item
        },
        updateAutoTimeItem(){
            if(!this.isManualTime){
                //set the end
                var index = this.selectedTimeData.autoTimeItems.length-1
                this.selectedTimeData.autoTimeItems[index].endDateTime=this.$options.filters.formatDateTime_Now()
            }
        },
        removeAutoTimeItem(){
            this.selectedTimeData.autoTimeItems.pop();
        }
      },
      mounted(){
        
        
      }
  }
  </script>
  
  <style>
  .gradecheckimage{
    height:20px;
  }
  .gradeboxlabel{
    margin:0px;
  }
  .statusDiv{
    
    display:grid;
    grid-template-rows: auto auto auto 100px;
  }
  .topDiv{
    display:grid;
    grid-template-columns: 300px auto 180px;
    
  }
  .gradesteps{
        padding:5px;
        background-color: var(--frame-color);
    }
  .commentlabel{
    margin:10px;
    padding: 0px 0px 0px 0px;
    height:90px;
    overflow-x:hidden;
    overflow-y:scroll;
  }
  .vertscroll{
    overflow-x:hidden;
    overflow-y:scroll;
    width:90%;
  }
  .leftborder{
    border-left:1px solid gray;
    margin-bottom:10px;
  }
  .rightborder{
    border-right:1px solid gray;
    margin-bottom:10px;
  }
  .total{
    text-align: left;
    font-size:larger;
    font-weight:bold;
  }
  .studentinfo{

    
  }
  .comments{
    background-color:var(--frame-color);
    margin:0px 10px 10px 10px;
    
  }
  .studentinfoandpic{
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin:0px 0px 0px 0px;
  }
.img{
    height:110px;
}
  .gradebutton{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    border:3px solid green;
    font-size:larger;
  }
  .gradebuttonlocked{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    color:black;
    border:1px solid gray;
    font-size:larger;
  }
    .headerlabel{
    
        margin:0px;
        /* font-size:larger; */
    }
    .steplabel{
        font-size:larger;
        margin:0px 0px 0px 0px ;
        padding: 5px 0px 5px 0px;
        font-weight:bold;
        background-color:#AFBCCE;
        width:100%;
    }
    .componentlabel{
        margin:10px 0px 0px 10px;
    }
    
    .studentname{
        margin:5px 5px 10px 5px;
        color:var(--text-color);
    }
    .larger{
        font-size:larger;
    }
    .step{
    
   }
  .component{
    border-bottom: 1px solid lightgray;
    margin-bottom:0px;
    /* margin-bottom:5px; */
    
  }
  .componentdiv{
    display:grid;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{

  }
  .graderow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px repeat(9,100px);
  }
  .graderowmulti{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px; 
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px 60px;
  }


  .headerrow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    display:grid;
    grid-template-columns: 250px repeat(9,100px);
  }
  .widebutton{

    border: none;
    border-radius:5px;
    width:180px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
    text-decoration: none;

  }
  .narrowbutton{

    border: none;
    border-radius:5px;
    width:50px;
    height:30px;
    padding:0px;
    font-size: medium;
    margin:5px;
    text-decoration: none;

}
.recordbutton{

border: none;
border-radius:5px;
width:70px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbutton{

border: none;
border-radius:5px;
width:80px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.datediv{
    height:50px;
    margin:0px 20px 0px 20px;
}
.datecheckdiv{
    margin:0px 20px 0px 20px;
}
.datelabel{
    margin:10px 10px 5px 5px;
}
.datecheck{
    margin-top:2px;
}
.dateinput{
    height:25px;
    margin-top:10px;
}
.gobutton{
    border: none;
border-radius:2px;
width:50px;
height:50px;
padding:0px;
font-size: medium;
margin:27px 10px 10px 10px ;
}
.innerbuttonwide{
    border: none;
border-radius:5px;
width:120px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonwider{
    border: none;
border-radius:5px;
width:185px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.filterfield{
    height:30px;
    margin:5px;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
}
.finishbutton{

border: none;
border-radius:5px;
width:150px;
height:40px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}



.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}
.sig{
    height:25px;
}
.manualTimeLabel{
    font-size:medium;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.manualTimeLabel2{
    font-size:medium;
    margin:12px 0px 0px 0px;
    color:var(--text-color);
}
.fixedcolumnlabel{
    width:80px;
    text-align: left;
}
.fixedcolumnlabelwide{
    width:150px;
    text-align: left;
}
.headerdivstatus{
    display:grid;
    grid-template-rows:  50px auto;
}
.fixedcolstu{
    
    width:100px;
    padding:0px;
    margin:0px;
  }
  .fixedcolstuwide{
    text-align:left;
    width:250px;
    padding:0px;
   margin:0px;
  }
  .center{
    text-align: center;
    justify-items: center;
  }
  .scrolldiv{
    height:350px;
    overflow-x:hidden;
    overflow-y:scroll;
   }
   .totallabel{
    margin-top:10px;
   }
   .scrolldivbatch{
    height:480px;
    width:95vw;
    overflow-x:scroll;
    overflow-y:scroll;
   }
  </style>

/* if(componentId==5){
    this.selectedGradeType='check'
    var g = this.getGrade(componentId,instance)
    if(g=='P'){
        this.setGrade(null)
    }else{
        this.setGrade('P')
    }
    
    return;
}

switch (componentId) {
    case 1:
        this.selectedGradeType='1to5'
        break;
    case 2:
        this.selectedGradeType='1to4'
        break;
    case 3:
        this.selectedGradeType='pf'
        break;
    case 4:
        this.selectedGradeType='enter'
        break;
    
        

    default:
        break;
} */

/* let req = {
    ProcedureInstance:this.selectedInstance,
    autoStartStopTime:!this.isManualTime
}
 this.$store.dispatch('beforeAPI');
var response = await this.callAPI(req,'canceltime')
//handle response
if(response.Successful){
    this.$emit('gotohome')           
}else{
    this.responseMessage = response.Message
}
this.pleaseWait=false;  */