import datamethods from './datamethods.js'
import {mapFields} from 'vuex-map-fields'
export default{
    mixins:[datamethods],
    computed:{
        ...mapFields([
            
            'loggedInUser',
            'selectedGradeSheet',
            'selectedTimeData',
            'isManualTime',
            'selectedInstanceList',
            'timeData',
            'lastWasPass',
            'confirmProficiencyText',
            'confirmProficiencyTitle',
            'evalToConfirm',
            

    

        ]),
        //settings
        allowConflictingTime(){
            if(window.ALLOW_CONFLICTING_TIME==undefined){return true}
            else{return window.ALLOW_CONFLICTING_TIME}
        },
        useCommentList(){
            if(window.USE_COMMENT_LIST==undefined){return false}
            else{return window.USE_COMMENT_LIST}
        },
        showCommentText(){
                return !this.useCommentList || this.isCustom
        },
        warnAboutTimeConflicts(){
            if(window.WARN_ABOUT_TIME_CONFLICTS==undefined){return false}
            else{return window.WARN_ABOUT_TIME_CONFLICTS}
        },
        CriticalMissText(){
            var txt = 'Critical Miss'
            if(window.CRITICAL_TEXT_OVERRIDE!=undefined){
                txt = window.CRITICAL_TEXT_OVERRIDE
            }
            return txt
        },
        showTimeAs24(){
            if(window.SHOW_TIME_AS_24==undefined){
                return false
            }else{
               return window.SHOW_TIME_AS_24
            }
        },

        FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
        showBTWDropDown(){
            
            if(window.SHOW_BTW_DROP_DOWN==undefined){
                return false
            }else{
               return window.SHOW_BTW_DROP_DOWN
            }
        },
        requireBTW(){
            if(window.REQUIRE_BTW==undefined){
                return false
            }else{
               return window.REQUIRE_BTW
            }
        },
        ShowSignature(){
            if(window.SHOW_SIGNATURE==undefined){return false}
            else{return window.SHOW_SIGNATURE}
        },
        RequireSignature(){
            if(window.REQUIRE_SIGNATURE==undefined){return false}
            else{return window.REQUIRE_SIGNATURE}
        },
        RequireSignatureStudent(){
            if(window.REQUIRE_SIGNATURE_STUDENT==undefined){return false}
            else{return window.REQUIRE_SIGNATURE_STUDENT}
        },
        
        RequireManualEntry(){
            if(window.REQUIRE_MANUAL_ENTRY==undefined){return false}
            else{return window.REQUIRE_MANUAL_ENTRY}
        },
        ShowStudentImage(){
            if(window.SHOW_STUDENT_IMAGE==undefined){return false}
            else{return window.SHOW_STUDENT_IMAGE}
        },
        
        ShowProficiency(){
            if(window.SHOW_PROFICIENCY==undefined){return false}
            else{return window.SHOW_PROFICIENCY}
        },
        AllowNAAsGrade(){
            if(window.ALLOW_NA_AS_GRADE==undefined){return false}
            else{return window.ALLOW_NA_AS_GRADE}
        },
        allowAddStudent(){
            if(window.BATCH_ALLOW_ADD_STUDENT==undefined){return false}
            else{return window.BATCH_ALLOW_ADD_STUDENT}
        },
        showIndividualCourseTimeToggle(){
            if(window.BATCH_ALLOW_INDIVIDUAL_COURSE_TIME==undefined){return false}
            else{return window.BATCH_ALLOW_INDIVIDUAL_COURSE_TIME}
            
        },
        indivBatchTimeText(){
            if(this.showIndivCourseTime){return "Enter single course time for all"}
            else{return "Enter individual course times"}
        },


        //seq settings
        showManualTime(){
            return this.selectedGradeSheet.showTimeOnManualEntry
        },
        showBreakTime(){
            return this.selectedGradeSheet.batch_ShowBreakTime
        },
        showIndividualBTW(){
            return this.selectedGradeSheet.batch_ShowIndividualBTW
        },
        showBTW(){
            return this.selectedGradeSheet.showBTW
        },
        selectedGradeType(){
            if (this.selectedGradeSheet.gradeType==null){
                return '1to5'
            }else{return this.selectedGradeSheet.gradeType}
                
        },
        showProficiencyForThisEval(){
            return this.selectedGradeSheet.showProficiency
        },
        usingChecks(){
            return this.selectedGradeType=='check'
        },
        usingPassFail(){
            return this.selectedGradeType=='pf'
        },
        BatchComponentsAsColumns(){
            if(this.selectedGradeSheet.batch_ComponentsAsColumns!=undefined){return this.selectedGradeSheet.batch_ComponentsAsColumns}
            if(window.BATCH_COMPONENTS_AS_COLUMNS==undefined){return false}
            else{return window.BATCH_COMPONENTS_AS_COLUMNS}
        },
    },
    data(){
        return{
            
        }
    },
    methods:{
       //time
       getNewTimeData(){
            
            var time = {
                instanceId:this.selectedInstance.ID,
                enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.StartDateTime),
                enteredCourseStartTime:'',
                enteredCourseEndTime:'',
                enteredBTWMinutes:-1,
                enteredCourseStartTime2:'',
                enteredCourseEndTime2:'',
                enteredBTWMinutes2:-1,
                autoTimeItems:[]

            }
            return time
        }, 
        getNewTimeData_Batch(){
            var time = {
                instanceId:this.selectedInstance.ID,
                enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.StartDateTime),
                enteredCourseStartTime:null,
                enteredCourseEndTime:null,
                enteredBTWMinutes:-1,
                autoTimeItems:[],
                batchInstances:[],
                isIndividualTime:false

            }
            return time
        },

        //submit helpers
        FixSubmitText(text){

            var out= text.replace('Passed', this.PassedText)
            out  = out.replace('Failed',this.FailedText)
            out = out.replace('Critical Miss', this.CriticalMissText)
            out = out.replace('CRITICAL MISS', this.CriticalMissText)
            out = out.replace('critical miss(es)', this.CriticalMissText)
            return out
        },
        instHasTimeFilled(inst){
            if(inst.enteredCourseStartTime==null || inst.enteredCourseEndTime==null || inst.enteredCourseStartTime=='' || inst.enteredCourseEndTime==''){
                return false
            }
            if(inst.enteredCourseStartTime.includes('0001-01-01') || inst.enteredCourseEndTime.includes('0001-01-01')){
                return false
            }
            //we made it!
            return true
        },
        PassedTimeChecks(issubmit){
            
            if(issubmit && this.isManualTime){
            if(this.RequireManualEntry){
                
                    
                //combo time
                if(this.selectedGradeSheet.isComboSequence){
                    //console.log('checktime')
                    //make sure at least one filled in
                    if(!this.comboRangeTimeFilledIn() && !this.comboRoadTimeFilledIn()){
                        this.responseMessage="Please fill in either road or range time before submitting."
                        return false
                    }
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    var sdate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                    var edate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                    if( sdate2 > edate2){
                        this.responseMessage = "Please enter a road course end time AFTER your road course start time"
                        return false
                    }
                   // console.log(sdate + ' ' + edate + ' ' + sdate2 + ' ' + edate2)
                    if((sdate2 < edate && sdate2 > sdate)||(sdate<edate2 && sdate >sdate2) || (edate<edate2 && edate >sdate2) ||(edate2<edate && edate2 >sdate)){
                        this.responseMessage = "Please enter non-overlapping times"
                        return false
                    }
                }else{
                    //non combo time
                    if (this.showManualTime && (this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null || this.selectedTimeData.enteredCourseStartTime=='' || this.selectedTimeData.enteredCourseEndTime =='' )){
                        this.responseMessage="Please enter course start and end times before submitting"
                        return false
                    }
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    //console.log(sdate + ' ' + edate )
                    if( sdate > edate){
                        this.responseMessage = "Please enter a course end time AFTER your course start time"
                        return false
                    }
                }

            }

            
            
            

            //var courseMinutes = Date.parse(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)-Date.parse(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
            var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
            var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
            
            var courseMinutes = date1-date2
            courseMinutes= (courseMinutes / (60000));
            
            if( this.selectedTimeData.enteredBTWMinutes==-1 && this.comboRangeTimeFilledIn() && this.showBTW && this.requireBTW){
                this.responseMessage="Please select BTW minutes"
                return false
            }
            
            if(courseMinutes<this.selectedTimeData.enteredBTWMinutes && this.showBTW && this.requireBTW){
                this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                return false
            }

            if(this.selectedGradeSheet.isComboSequence){
                var date3= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                var date4 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                
                var courseMinutes2 = date3-date4
                courseMinutes2= (courseMinutes2 / (60000));

                if( this.selectedTimeData.enteredBTWMinutes2==-1 && this.comboRoadTimeFilledIn() && this.showBTW && this.requireBTW ){
                    this.responseMessage="Please select BTW minutes"
                    return false
                }

                if(courseMinutes2<this.selectedTimeData.enteredBTWMinutes2 && this.showBTW && this.requireBTW){
                    this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                    return false
                }
            }
            }
            //if we got here return true
            return true
        },
        PassedCommentChecks(issubmit){
            //if we are requiring comments... 
            this.selectedInstance.HasCommentForThisInstance=(this.selectedInstance.enteredComment!=null && this.selectedInstance.enteredComment!='')
            if(window.REQUIRE_XCHARACTER_COMMENT>0 && issubmit){
                if(!this.selectedInstance.HasCommentForThisInstance && !this.lastWasPass && this.selectedGradeSheet.hasFailureRules){
                    this.responseMessage = "Please enter a comment before submitting"
                    return false
                }else if(!this.selectedInstance.HasCommentForThisInstance && !this.selectedGradeSheet.hasFailureRules){
                    this.responseMessage = "Please enter a comment before submitting"
                    return false
                }
            }
            if(this.selectedInstance.HasCommentForThisInstance){
                if(this.selectedInstance.enteredComment.length>4999  ){
                    this.responseMessage = "Please enter a comment less than 5000 characters long"
                    return false
                }
            }

            return true
        },
        PassedSignatureChecks(issubmit){
            if(this.RequireSignature && this.selectedInstance.signatureFileName==null && issubmit){
                this.responseMessage = "Please have instructor sign before submitting"
                return false
            }
            if(this.RequireSignatureStudent && this.selectedInstance.signatureFileName_Student==null && issubmit && this.selectedGradeSheet.showStudentSignature){
                this.responseMessage = "Please have student sign before submitting"
                return false

            }
            return true
        },
        PassedDataChecks(issubmit){
            if(this.selectedGradeSheet.allowEnterMiles && (this.selectedInstance.miles==null || this.selectedInstance.miles=='') && issubmit){
                if(this.selectedGradeSheet.isComboSequence && !this.comboRoadTimeFilledIn()){
                    //no msg. if we didn't have road time, it doesn't matter
                }else{
                    this.responseMessage = "Please enter miles before submitting"
                    return false
                }
                
            }
            return true
        },
        comboRangeTimeFilledIn(){
            //  console.log('checkingrangetimefilled')
              var filled =true
              if (this.showManualTime && (this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null || this.selectedTimeData.enteredCourseStartTime=='' || this.selectedTimeData.enteredCourseEndTime =='' )){
                  filled=false
                              
              }
            //  console.log('have range time ' + filled)
              return filled
          },
          comboRoadTimeFilledIn(){
              var filled =true
              if (this.showManualTime && (this.selectedTimeData.enteredCourseStartTime2==null || this.selectedTimeData.enteredCourseEndTime2==null || this.selectedTimeData.enteredCourseStartTime2=='' || this.selectedTimeData.enteredCourseEndTime2 =='' )){
                  filled=false
                              
              }
           //   console.log('have road time ' + filled)
              return filled
          },
        //sig
        async savesign_document(file,url,docname,docid, enteredName){
          
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('instanceid',this.selectedInstance.ID);
            postParams.append('enteredname',enteredName);

            var response = await this.callAPI(postParams,'savesignature')
            //handle response
            if(response.Successful){
                 this.refreshSignature()
            }else{
                this.responseMessage = response.Message
            }
        },
        async savesign_document_student(file,url,docname,docid, enteredName){
          
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('instanceid',this.selectedInstance.ID);
            postParams.append('enteredname',enteredName);

            var response = await this.callAPI(postParams,'savesignaturestudent')
            //handle response
            if(response.Successful){
                this.refreshSignature()
            }else{
                this.responseMessage = response.Message
            }
        },
        async savesign_document_batch(file,url,docname,docid, enteredName){
          
            var postParams = new FormData();
  
            postParams.append("sign",file,name);
            postParams.append('instanceid',this.selectedInstance.evalBatchNumber);
            postParams.append('enteredname',enteredName);
  
            var response = await this.callAPI(postParams,'savesignature_batch')
            //handle response
            if(response.Successful){
                 this.refreshSignature()
            }else{
                this.responseMessage = response.Message
            }
        },
        async refreshSignature(){
            let req = {
                ProcedureInstance:this.selectedInstance,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'signature')
            //handle response
            if(response.Successful){
                this.selectedInstance.signatureFileName = response.signatureFileName;        
                this.selectedInstance.signatureFileName_Student = response.signatureFileName_Student; 
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        //cache
        GetSequence(id) {
          //  console.log('get seq  ' + id)
            var sequences=JSON.parse(localStorage.sequences)
            var found=false
            var returnseq={}
            sequences.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let seq=arr[index]
                    if(seq.id==id){
                        returnseq=seq
                        found=true
                    }
                }
            
            return returnseq
        },
        async GetSequence_Add(id) {
            //  console.log('get seq  ' + id)
              var sequences=JSON.parse(localStorage.sequences)
              var found=false
              var returnseq={}
              sequences.forEach(myFunction2)
                  async function myFunction2(item, index, arr) {
                      let seq=arr[index]
                      if(seq.id==id){
                          returnseq=seq
                          found=true
                      }
                  }
              if(!found){
                var sq = await this.getSingleSequenceForCache(id)
                return sq
              }
              return returnseq
          },
        GetSequence_Batch_ShowPreviousInstances(id){
           var showprev = true
            try {
                var sequences=JSON.parse(localStorage.sequences)
                var returnseq={}
                var found=false
                sequences.forEach(myFunction2)
                    async function myFunction2(item, index, arr) {
                        let seq=arr[index]
                        if(seq.id==id){
                            found=true
                            returnseq=seq
                        }
                    }
                if(found){showprev= returnseq.batch_ShowPreviousInstances}
            } catch (error) {
                
            }
           // console.log('showprev ' + id + ' ' + showprev)
            return showprev
        },
        saveInstanceToCache(){
           // console.log('savetocache')
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.selectedInstance
           // selInst.Procedure = this.selectedGradeSheet
            var myinst
            
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst=selInst //replace with edited one
                        inst.dirty=true
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
           
        },
        clearDirtyBitInCache(){
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.selectedInstance
           // selInst.Procedure = this.selectedGradeSheet
            var myinst
            
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst.dirty=false
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
      
        },
        revertCachedChanges(){
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.unchangedInstance//get the unchanged instance
            if(this.unchangedInstance==undefined){return}
            var myinst
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst=selInst //replace with edited one
                        
                        inst.dirty=false //we just put it back, doesn't need saving
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
           
        },
        haveSequence(sequences,id){
            //console.log(sequences.length)
            var haveit=false
            if(sequences.length>0){
                sequences.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let seq=arr[index]
                    if(seq.id==id){
                        haveit=true
                    }
                }
            }
            
            return haveit
        },
        async getSingleSequenceForCache(id){
             console.log('get 1 sequences' + id)
             var scope = this;
             var cacheseq = localStorage.sequences
             var sequences=[]
             if(cacheseq!=undefined){
                 sequences=JSON.parse(cacheseq)
             }
           
           
                     //see if we already have it
                     if(!scope.haveSequence(id)){
                         //NOPE! go get it
                        // console.log('get seq ' + inst.ProcedureSequenceId )
                         let req = {
                             id:id,
                         }
                         var response = await scope.callAPI(req,'proceduresequence')
                         //handle response
                         if(response.Successful){
                       
                             sequences.push(response.Procedure)
                            // console.log(sequences.length)
                             localStorage.sequences = JSON.stringify(sequences)
                             //return response.Procedure
                         }
                     }else{
                         //console.log('HAVE seq ' + inst.ProcedureSequenceId)
                     }
                        
             
             localStorage.sequences=JSON.stringify(sequences)
             
         },
        //time cache
        removeTimeDataForSubmittedInstance(instId){
            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData
            
           
            this.timeData = JSON.parse(tmptime);
            var foundTimeData=null
            var itemindex
            this.timeData.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.instanceId==instId){
                    foundTimeData=comp
                    itemindex = index
                }
            }

            //remove
            this.timeData.splice(itemindex,1)

            //save
            this.saveTimeDataToStorage()
        },
        saveTimeDataToStorage(){
            //make sure we have 24
            if(this.showTimeAs24){
                this.selectedTimeData.enteredCourseStartTime=this.starttime24
                this.selectedTimeData.enteredCourseEndTime=this.endtime24
                this.selectedTimeData.enteredCourseStartTime2=this.starttime242
                this.selectedTimeData.enteredCourseEndTime2=this.endtime242
            }
            if(this.showBTWDropDown){
                this.selectedTimeData.enteredBTWMinutes=this.selectedBTWMinutes
                this.selectedTimeData.enteredBTWMinutes2=this.selectedBTWMinutes2
            }
            try {
                localStorage.timeData=JSON.stringify(this.timeData)
            } catch (error) {
                console.error(error)
            }

            
        },

        saveTimeDataToStorage_Batch(){
            //update batch data
            //if we have instance specific data
             var binst=[]
            this.selectedInstanceList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    let insttime = {ID:inst.ID,
                                    enteredBTWMinutes:inst.enteredBTWMinutes,
                                    enteredCourseStartTime:inst.enteredCourseStartTime,
                                    enteredCourseEndTime:inst.enteredCourseEndTime,
                                    enteredCourseDate:inst.enteredCourseDate,
                                    enteredComment:inst.enteredComment,
                                    enteredBreak:inst.enteredBreak,
                                    HasCommentForThisInstance:inst.HasCommentForThisInstance}
                    binst.push(insttime)

                    
                }

            this.selectedTimeData.batchInstances=binst
            if(this.showBTWDropDown){
                this.selectedTimeData.enteredBTWMinutes=this.selectedBTWMinutes
                this.selectedTimeData.enteredBTWMinutes2=this.selectedBTWMinutes2
            }
            try {
                localStorage.timeData=JSON.stringify(this.timeData)
            } catch (error) {
                console.error(error)
            }
        },

        //auto time
        getNewAutoTimeItem(){
            var item={
                startDateTime:this.$options.filters.formatDateTime_Now(),
                endDateTime:null
            }
            return item
        },
        updateAutoTimeItem(){
            if(!this.isManualTime){
                //set the end
                var index = this.selectedTimeData.autoTimeItems.length-1
                this.selectedTimeData.autoTimeItems[index].endDateTime=this.$options.filters.formatDateTime_Now()
            }
        },
        removeAutoTimeItem(){
            this.selectedTimeData.autoTimeItems.pop();
        },

        //proficiency
        async checkProficiency(i){
            console.log(i)
            let req = {
                ProcedureInstance:i,
                loggedInUser:this.loggedInUser.user_personId

            }
            console.log(req)
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'checkproficiency')
            //handle response
            if(response.Successful){
                if(response.StringResponse==null || response.StringResponse==''){
                    return this.toggleProficientFlag(i,true)

                }else{
                    console.log('failed prof check')
                    this.evalToConfirm=i
                    this.confirmProficiencyText=response.StringResponse
                    this.confirmProficiencyTitle="Confirm Set Proficiency"
                    this.pleaseWait=false; 
                    return false
                }
                    
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async toggleProficientFlag(i,alreadychecked=false){
            //console.log(i)
            if(!this.online){this.handleOffline(); return}
            if(!alreadychecked && !i.StudentIsProficient_ThisItem){
                return this.checkProficiency(i)
                
            }

            let req = {
                ProcedureInstance:i,
                loggedInUser:this.loggedInUser.user_personId

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'toggleproficiency')
            //handle response
            if(response.Successful){
                i.StudentIsProficient_ThisItem=!i.StudentIsProficient_ThisItem
                i.ProficiencyInstructor_ThisItem= this.loggedInUser.user_personId
                this.pleaseWait=false; 
                return true    
            }else{
                this.responseMessage = response.Message
                
            }
            this.pleaseWait=false; 
            
        },
    }

}