<template>
    <div class="gradeDiv" id="printableArea">
        
        <div class="horizFlexStretch fixwidth">
            <div class="leftjustified rightborder">
                <p class="studentname larger">Enter Grades</p>
                <p class="studentname ">{{ selectedInstance.Name }}</p>
                <p class="studentname ">{{ selectedInstance.LocationName }}</p>
                <img :src="selectedInstance.signatureFileName" class="sig"/>  
                
                <smallbutton :toplabel="filterlabel" @click="showAllComponents=!showAllComponents" buttonclass="buttonprimary innerbuttonwide" v-if="!selectedInstance.IsLocked && selectedGradeSheet.batch_SelectComponents"/>
                <smallbutton toplabel="Sign"  @click="openSignModal(selectedGradeSheet.id, selectedGradeSheet.id,selectedGradeSheet.id)" 
                        buttonclass="buttonprimary innerbutton" v-if="ShowSignature"/>
                <smallbutton toplabel="Print" @click="print('printableArea')" buttonclass="buttonprimary innerbuttonwide" v-if="selectedInstance.IsLocked"/>
            </div>
            
            <div :class="centerdivclass">
                <div class="truckroutediv">
                    <label class="truckroutelabels" v-if="selectedInstance.truckNumber!=null">Truck: {{ selectedInstance.truckNumber }}</label>
                    <label class="truckroutelabels " v-if="selectedInstance.routeNumber!=null">Route: {{ selectedInstance.routeNumber }}</label>
                    <label class="truckroutelabels " v-if="selectedInstance.trailerNumber!=null">Trailer: {{ selectedInstance.trailerNumber }}</label>
                    <label class="truckroutelabels " v-if="selectedInstance.miles!=null">Miles: {{ selectedInstance.routeNumber }}</label>
                    
                </div>
                <div class="horizFlex"  >
                    
                    
                    <div class="horizFlex" v-if="!showIndivCourseTime">
                        <p class="manualTimeLabel">Course Date/Time</p>
                        <input class="filterfield" type="date" v-model="selectedTimeData.enteredCourseDate" :disabled="disableTime">
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseStartTime" step="900" :disabled="disableTime" v-if="showManualTime">
                        <p class="manualTimeLabel2" v-if="showManualTime">to</p>
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseEndTime" step="900" :disabled="disableTime" v-if="showManualTime">
                        <p class="manualTimeLabel" v-if="showManualTime && showBreakTime">Break Min</p>
                        <input class="filterfield" type="" v-model="selectedTimeData.enteredBreak" :disabled="disableTime" v-if="showManualTime && showBreakTime">
                        <linkbutton :toplabel="indivBatchTimeText" @click="toggleIndivCourseTime" v-if="showIndividualCourseTimeToggle" class="margin10"/>
                    </div>
                    
                    <div v-if="showIndivCourseTime" class="vertscroll">
                        <linkbutton :toplabel="indivBatchTimeText" @click="toggleIndivCourseTime" v-if="showIndividualCourseTimeToggle"/>
                        <div class="studenttimegrid">
                            <p class="manualTimeLabel">Course Date</p>
                            <input class="filterfield" type="date" v-model="selectedTimeData.enteredCourseDate" :disabled="disableTime">
                        </div>
                        
                        <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="studenttimegrid">
                            <p class="manualTimeLabel2" v-if="showManualTime">{{h.StudentName}}</p>
                            <input class="filterfield" type="time" v-model="h.enteredCourseStartTime" step="900" :disabled="disableTime" v-if="showManualTime">
                            <p class="manualTimeLabel2" v-if="showManualTime">to</p>
                            <input class="filterfield" type="time" v-model="h.enteredCourseEndTime" step="900" :disabled="disableTime" v-if="showManualTime">
                            <p class="manualTimeLabel" v-if="showManualTime && showBreakTime">Break Min</p>
                            <input class="filterfield" type="" v-model="h.enteredBreak" :disabled="disableTime" v-if="showManualTime && showBreakTime">
                        </div>
                    </div>
                    <label class="greenlabel margin10" v-if="anyTimeApproved">Time Approved</label>
                    <smallbutton toplabel="Update" @click="updateTime" buttonclass="buttonprimary innerbutton" v-if="disableTime && !anyTimeApproved"/>
                    <smallbutton toplabel="Save" @click="saveupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                    <smallbutton toplabel="Cancel" @click="cancelupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                    
                    
                </div>
                
            </div>

            <div class=" vertFlex">
                <smallbutton toplabel="Submit Grades" @click="savegrades(true)" buttonclass="buttonprimary finishbutton" v-if="!selectedInstance.IsLocked"/>
                <confirm v-if="!selectedInstance.IsLocked" buttonclass="buttonprimary finishbutton" :showButton="paramTrue" body="Do you just want to save your progress? If you are finished grading, please click on the Submit Grades button instead." title="Save Progress"
                            @yes="savegrades(false)"  noLabel="Cancel"/>
                    <!-- <smallbutton toplabel="Save Grades" @click="savegrades(false)" buttonclass="buttonprimary finishbutton" v-if="!selectedInstance.IsLocked"/> -->
                    <smallbutton toplabel="Close" @click="home" buttonclass="buttonprimary finishbutton" v-if="selectedInstance.IsLocked"/>
                    <linkbutton toplabel="Cancel" @click="cancelGrading" buttonclass="buttonprimary" v-if="!selectedInstance.IsLocked"/>
                    

            </div>
        
        </div>
        
        <div class="leftjustified">
            <p class="responsemessage" v-if="responseMessage!=null">{{ responseMessage }}</p>
            <label class="pleasewait" v-if="pleaseWait">Please Wait...</label>
        </div>
        
        <div class="gradesteps">
            
            
            <!-- StudentsAsColumns -->
           <div v-if="!BatchComponentsAsColumns">
                <!-- headers -->
                <div class="horizFlex" >
                    <div class="headerdiv fixedcolstuwide">
                        <p class="headerlabelplaceholder" v-if="!allowAddStudent"></p>
                        <smallbutton toplabel="Add Student to Group"  @click="addStudent" 
                            buttonclass="buttonprimary innerbuttonadd" v-if="allowAddStudent"/>
                        <div class="headerdivitem40">
                            <p class="headerlabel rightjustified " >Click Name to Enter Comments</p>
                        </div>
                            
                        <div class="headerdivitem40" v-if="showManualTime && showIndividualBTW && showBTW">
                            <p class="  rightjustified topmargin" >BTW Minutes</p>
                        </div>
                        <div class="headerdivitem50" v-if="showProficiencyButton">
                            <p class=" rightjustified topmargin" >Update Proficiency</p>
                        </div>
                        
                        
                    </div>
                    
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="headerdiv center fixedcolstu">
                        <!-- <p class="headerlabel">{{ h.StudentName}}</p> -->
                        <img :src="imagePathPerson(h)" :class="imgclass" v-if="ShowStudentImage" @click="toggleimg"/>
                        <div class="headerdivitem40">
                            <linkbutton :toplabel="h.StudentName" @click="showcomments(h)" :class="namelinkclass(h)"/>
                        </div>
                        
                        <div class="headerdivitem40" v-if="showManualTime && showIndividualBTW && showBTW">
                            <input class="btwfield" type="" v-model="h.enteredBTWMinutes" :disabled="disableTime" v-if="showManualTime && showIndividualBTW && !showBTWDropDown" placeholder="BTW">
                            <select name="" id="" class='btwdropdown'  v-if="showManualTime && showBTWDropDown && showIndividualBTW" v-model="h.enteredBTWMinutes" :disabled="disableTime">
                                <option v-for="c in btwMinutesList" :key="c.id" :value="c.id" >
                                    {{c.name}}
                                </option>
                            </select>
                        </div>
                        <div class="headerdivitem50" v-if="showProficiencyButton">
                            <div class="vertFlex" v-if="showProficiencyButton">
                                <confirm :buttonclass="proficiencyClass(h)" :showButton="paramTrue" body="Do you want to update the proficiency?" :title="proficiencyLabelFull(h)"
                                        @yes="toggleProficient(h)" :buttonLabel="proficiencyLabel(h)" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            
                <!-- steps and components -->
                <div class="scrolldiv">

                
                <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="step" >
                    <div > 
                        <p class=" steplabel">{{s.Name}}</p>
                    </div>
                    
                    <div :class="componentclass">
                        <div v-for="c in filteredComponents(s)" :key="c.ID" :value="c.ID" class="component ">
                            <div v-if="c.IsActive">
                                <div class="horizFlex" >
                                    <p class="componentlabelstu fixedcolstuwide">{{c.Name}}</p>
                                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="centered fixedcolstu">
                                        <button  @click="grademe(c.ID,h)" :class="gradebuttonclass(c.ID,h)" :disabled="!isGradable_flat(c.ID,h)">
                                            <gradeitem :usingChecks="usingChecks" :grade="getGrade_flat(c.ID,h)" :showCheck="showCheck(c.ID,h)" :key="batchKey"/>
                                            <!-- <div>
                                                <p class="gradeboxlabel" v-if="!usingChecks">{{ getGrade(c.ID,h) }}</p>
                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                            </div> -->
                                        
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>              
                    </div>
                </div>

                </div>
                <!-- totals -->
                <div class="horizFlex" v-if="selectedGradeSheet.showTotal">
                    <p class="bold fixedcolstuwide">{{totallabel}}</p>
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" class="centered">
                        <p class="bold fixedcolstu">{{ total_flat(h)}}</p>
                    </div>
                </div>
           </div>

           <!-- ComponentsAsColumns -->
           <div v-if="BatchComponentsAsColumns">
                <div class="scrolldivbatch"> 
                    <!-- headers -->
                    <div class="horizFlex" >
                        <p class="fixedcolumnlabelwide"></p>
                        <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="horizFlex" >
                            <div v-for="c in filteredComponents(s)" :key="c.ID" :value="c.ID" class="component">
                                <p class="fixedcolumnlabel">{{abbrevComp(c.Name)}}</p>
                            </div>
                        </div>
                        <p class="bold left10" v-if="selectedGradeSheet.showTotal && !selectedGradeSheet.useAverageForTheoryFinal">{{totallabel}}</p>
                    </div>
                
                    <!-- steps and components -->
                
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID"  >
                        <div class="horizFlex " >
                            <p class="fixedcolumnlabelwide">{{h.StudentName}}</p>
                            
                            <div class=" horizFlex" >
                                <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="horizFlex" >
                                    <div v-for="c in filteredComponents(s)" :key="c.ID" :value="c.ID" class="fixedcolumnlabel">
                                        <button  @click="grademe(c.ID,h)" :class="gradebuttonclass(c.ID,h)" :disabled="!isGradable_flat(c.ID,h)">
                                            <gradeitem :usingChecks="usingChecks" :grade="getGrade_flat(c.ID,h)" :showCheck="showCheck(c.ID,h)"/>
                                            <!-- <div>
                                                <p class="gradeboxlabel" v-if="!usingChecks" >{{ getGrade(c.ID,h) }}</p>
                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                            </div> -->
                                        
                                        </button>
                                    </div>  
                                </div> 

                                <!-- totals -->
                                <div class="" v-if="showComponentTotal">
                                    
                                    <p class="bold">{{ total_flat(h)}}</p>
                                    
                                </div>
                            </div>
                                    
                        </div>
                    </div>
                </div>
                   
                
                
                <!-- totals -->
                <!-- <div class="headerrow" >
                    <p class="bold left10">{{totallabel}}</p>
                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" >
                        <p class="bold">{{ total(h)}}</p>
                    </div>
                </div> -->
           </div>
            
  
            
        </div>
        <grade15 ref="grade15" @select="setGrade" :gradeType="selectedGradeType"/>
        <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
        <components ref="components" @save="savecomponents()" @cancel="cancelGrading"/>
        <comment ref="comment" @refreshcomments="refreshComments" @savecomment="saveComment"/>
        <signaturemodal ref="signdoc" @savesign="savesign_document_batch" />
        <addstudent ref="addstudenttobatch" @save="saveaddstudent" :locationId="selectedInstance.LocationID"/>
        <confirmtimeconflict ref="confirmsubmit"  :timeConflictList="timeConflictList"
                            @yes="finishsubmitinstortime()" :redText="paramTrue" />
        <confirm ref="confirmprof" :showButton="paramFalse" :body="confirmProficiencyText" :title="confirmProficiencyTitle"
                            @yes="toggleProficientFlag(evalToConfirm,true)" :redText="paramTrue"  />
    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import grade15 from './grade15.vue'
  import components from './components.vue'
  import signaturemodal from '../controls/signaturemodal.vue'
  import modalmessage from '../controls/modalmessage.vue'
  import comment from './comment.vue'
  import confirm from '../actions/confirm.vue'
  import confirmtimeconflict from '../actions/confirmtimeconflict.vue'
  import gradeitem from './gradeitem.vue'
  import addstudent from './addstudent.vue'
  import evalhelper from '../../services/evalhelper.js'
  import securityhelper from '../../services/securityhelper.js'
  export default {
      name:'gradesheetbatch',
      mixins:[datamethods, format,evalhelper,securityhelper],
      components:{
          smallbutton,
          linkbutton,
          grade15,
          modalmessage,
          confirm,
          components,
          gradeitem,
          comment,
          signaturemodal,
          addstudent,
          confirmtimeconflict
      },
      data(){
          return{
            insave:false,
            batchKey:0,

            zoom:1,
            showAllComponents:true,
            selectedComponents:[],

            selectedComponent:0,
            
            

            paramTrue:true,
            paramFalse:false,   
            listenersConnected:true,
            
           
            proficient:false,
            calcTotal:0,
            multColumnOverride:-1,

            inTimeUpdate:false,
            selectedInstanceForComment:null,
            imgbig:false,
            showIndivCourseTime:false,
            timeConflictList:[]
          }
      },
      computed:{
        showProficiencyButton(){
            return this.showProficiencyForThisEval && this.ShowProficiency && this.canAccess('Set Proficiency')
        },
        showComponentTotal(){
            return this.selectedGradeSheet.showTotal && (!this.selectedGradeSheet.useAverageForTheoryFinal ||(this.selectedGradeSheet.useAverageForTheoryFinal && !this.selectedInstance.IsLocked))
        },
       
        
        imgclass(){
            if(this.imgbig){return "img2big"}
            else{return "img2"}
        },
        
     
        
        disableTime(){
            if(this.inTimeUpdate){return false}
            return (this.selectedInstance.IsLocked || this.anyTimeApproved)
        },
        filterlabel(){
            if(this.showAllComponents){return "Show Filtered"}
            else{return "Show All"}
        },
        selectedInstanceComment(){
            return this.selectedInstance.OverallGradeList_Instructor[0].Comment
        },
        checkImage(){
            return require("@/assets/check.png")
        },
        totallabel(){
            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return 'Passed: ' 
            }else if(this.selectedGradeSheet.showTotalAsSum){
               
                return 'Total: ' 
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                
                return 'Avg: '
            }else if(this.selectedGradeSheet.showTotalOutOf100){
             
                return 'Out of 100: '
            }else{
                
            }
        },
        
       
        multiColumnCount(){
            var cols=4
            if(window.DEFAULT_COLUMN_COUNT!=undefined){cols=window.DEFAULT_COLUMN_COUNT}

            if(this.multColumnOverride>0){cols = this.multColumnOverride}
            return cols
        },
        
  
        componentclass(){
            return "componentdiv zoom" + this.zoom
        },
    
        centerdivclass(){
            if(this.showIndivCourseTime){
                return ""

            }else{return "toBottom"}
        },
          ...mapFields([
              'loggedInUser',
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'selectedGradeSheet',
             'selectedInstance',
             'selectedInstanceList',
             'isManualTime',
             'unchangedInstance',
             'online',
             'responseMessage',
             'selectedTimeData',
             'timeData',
             'btwMinutesList',
             'confirmProficiencyText',
            'confirmProficiencyTitle',
            'evalToConfirm',
          ]),
          anyTimeApproved(){
            var anyapproved=false
            this.selectedInstanceList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.timeApproved_Course){
                    anyapproved=true

                }
                
            }
            return anyapproved
          }
        
      },
      props:{
          
          
          
      },
      methods:{
        saveComment(id,c){
            if(c!=null){
                c = c.trim() //trim any whitespace
            }
            
            console.log(id + ' ' + c)
            this.selectedInstanceList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(arr[index].ID==id){
                    
                    arr[index].enteredComment=c
                    if(c!=null && c!=''){arr[index].HasCommentForThisInstance=true}
                    else{arr[index].HasCommentForThisInstance=false}
                    console.log('match! ' + arr[index].enteredComment)
                }
                
            }
        },
        finishsubmitinstortime(){


            if(this.selectedInstance.IsLocked){
                this.finishsubmittime()
            }else{
                this.finishSubmit()
            }
        },
        namelinkclass(i){
            if(i.HasCommentForThisInstance){return "greenlabel"}
            else{return ""}
        },
        addStudent(){
            this.$refs.addstudenttobatch.Update();
        },
        async saveaddstudent(id){
            this.saveTimeDataToStorage_Batch()
            //console.log('addstu ' + id)
            let req = {
                locationId:this.selectedInstance.LocationID,
                    courseId:this.selectedInstance.courseId,
                    procedureId:this.selectedInstance.ProcedureSequenceId,
                    locationStationId:this.selectedInstance.LocationStationID,
                    instructorId:this.selectedInstance.InstructorId,
                    studentList:[{id:id, selected:true}],
                    truckNumber:this.selectedInstance.truckNumber,
                    routeNumber:this.selectedInstance.routeNumber,
                    trailerNumber:this.selectedInstance.trailerNumber,
                    miles:this.selectedInstance.miles,
                    evalBatchNumber:this.selectedInstance.evalBatchNumber,
                    signatureFileName:this.selectedInstance.signatureFileName
            }

            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'creategradesheet')
            //handle response
            if(response.Successful){
                this.saveonly()
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 

        },
        async refreshme(){
          //  console.log('get batch')
            let req = {
                evalBatchNumber:this.selectedInstance.evalBatchNumber,
                procedureSequenceId:this.selectedInstance.ProcedureSequenceId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance_batch')
            //handle response
            if(response.Successful){
              //   this.selectedGradeSheet = response.ProcedureSequence
                 this.selectedInstanceList = response.ProcedureInstanceList
                this.selectedInstance = this.selectedInstanceList[0]
                this.getInstanceTimeDataFromStorage()
                this.pleaseWait=false;
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
        toggleIndivCourseTime(){
            this.showIndivCourseTime=!this.showIndivCourseTime
            this.selectedTimeData.isIndividualTime=this.showIndivCourseTime
            if(this.showIndivCourseTime && !this.selectedInstance.IsLocked){
                //prepop if not locked

                var mythis = this
                var start = this.selectedTimeData.enteredCourseStartTime
                var end  =this.selectedTimeData.enteredCourseEndTime
                var brk = this.selectedTimeData.enteredBreak
                this.selectedInstanceList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.enteredCourseStartTime=start
                        comp.enteredCourseEndTime=end
                        comp.enteredBreak=brk
                    }
            }
        },
        proficiencyClass(i){
            if(i.StudentIsProficient_ThisItem==true){
                return "buttongreen innerbuttonprof"
            }else{return "buttonred innerbuttonprof"}
        },
        proficiencyLabel(i){
            var lbl
            if(i.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}

            return lbl
        },
        proficiencyLabelFull(i){
            var lbl
            if(i.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}

            if (i.Name.toLowerCase().includes('range')){
                lbl=lbl + ' on Range'
            }else if (i.Name.toLowerCase().includes('road')){
                lbl=lbl + ' on Road'
            }
            return lbl
        },
        
      
        openSignModal(docname,docid,doctypename){
            if(!this.online){this.handleOffline(); return}
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },
        toggleimg(){
            this.imgbig=!this.imgbig
        },
        imagePathPerson(i){
            return i.studentImageFileName
           
          },
        filteredComponents(step){
            
            var filt=[]

            if(this.showAllComponents){
                step.StepComponentList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        if(comp.IsActive){
                            filt.push(comp)
                        }
                    }
                return filt
            }
            var scope = this

            //this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
            step.StepComponentList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.selected && comp.IsActive){
                    filt.push(comp)
                }
            }
            return filt
        },
        updateTime(){
            this.inTimeUpdate=true

        },
        cancelupdateTime(){
            //put the data back
            this.selectedTimeData={
                    instanceId:this.selectedInstance.ID,
                    enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                    enteredCourseStartTime:this.selectedInstance.enteredCourseStartTime,
                    enteredCourseEndTime:this.selectedInstance.enteredCourseEndTime,
                    enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                    autoTimeItems:[]
                }

            this.responseMessage=null
            this.inTimeUpdate=false
        },
        async saveupdateTime(){
            this.responseMessage=null
            if(this.RequireManualEntry){
                if( this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null){
                    this.responseMessage="Please enter course start and end times before submitting"
                    return
                }
                
            }
            var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
            var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
            if( sdate > edate){
                this.responseMessage = "Please enter a course end time AFTER your course start time"
                return
            }
            //main course minutes
            var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                
                var courseMinutes = date1-date2
                courseMinutes= (courseMinutes / (60000));
                
                //now go through the instances
                var mythis = this
                var toomuchbtw=false
                this.selectedInstanceList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]

                    //if we are doing indiv time...need new course time
                    var indivcourseminutes=courseMinutes
                    if(mythis.showIndivCourseTime){
                        var date3= new Date(mythis.selectedTimeData.enteredCourseDate + ' ' + comp.enteredCourseEndTime)
                        var date4 = new Date(mythis.selectedTimeData.enteredCourseDate + ' ' + comp.enteredCourseStartTime)
                        
                        var indivcourseminutes = date3-date4
                        indivcourseminutes= (indivcourseminutes / (60000));
                    }
                   // console.log('indiv min ' + indivcourseminutes + ' btw ' + comp.enteredBTWMinutes)
                    if(indivcourseminutes<comp.enteredBTWMinutes){
                        toomuchbtw=true
                    }
                }


                if(toomuchbtw){
                    this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                    return
                }

            //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
            if(this.warnAboutTimeConflicts ){this.checkForTimeConflicts();return;}
            //if NOT, then go ahead

            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                timeData:this.selectedTimeData
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'savetime_batch')
            //handle response
            if(response.Successful){
                this.inTimeUpdate=false       
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        
        },
        savecomponents(){
            this.showAllComponents=false

        },
       /*  setcols(f,l){
            this.firstcol=f
            this.lastcol=l
        }, */
        
        abbrevComp(name){
            var out= name
            out= out.replace('Chapter','Ch').replace('Score','')
            out =out.replace('Chap','Ch')
            var dash = out.indexOf('-')
            if(dash>0){out = out.substring(0,dash-1)}
            return out
        },
        total(inst){
            var mythis = this
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = 0;
            var allgradecount  = inst.ProcedureStepComponentGrade_InstructorList.length-1
            inst.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.Grade!=null){
                    if(comp.Grade != 'NA' && comp.Grade !='IA'){
                        total = total + Number(comp.Grade)
                        count = count + 1
                    }
                    
                }
                if(comp.Grade=='P' || comp.Grade=='1'){
                    passcount = passcount + 1
                    
                }
                pfcount=pfcount + 1
            }
            inst.instanceHistory.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    var isactive = mythis.isActive(comp.RelatedID)
                   
                    if(isactive){
                       
                        if(comp.Grade!=null){
                            if(comp.Grade != 'NA' && comp.Grade !='IA'){
                                total = total + Number(comp.Grade)
                                count = count + 1
                            }
                            
                        }
                        if(comp.Grade=='P'){
                            passcount = passcount + 1
                            
                        }
                        pfcount=pfcount + 1
                    }
                    
                }
            }
          
            avg = total/count;
            outof100=100-total;

            //figure out if we are all full
            inst.isFinalSubmit = (allgradecount<=count)
           
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return passcount + ' out of ' + count;
            }else if(this.selectedGradeSheet.showTotalAsSum){
               // this.calcTotal = total
               inst.calculatedTotal=total
                return  total;
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                //this.calcTotal = avg.toFixed(2);
                inst.calculatedTotal=avg.toFixed(2);
                return  avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalOutOf100){
                //this.calcTotal=outof100multiColumnCount
                inst.calculatedTotal=outof100multiColumnCount
                return  outof100;
            }else{
                //this.calcTotal=total
                inst.calculatedTotal==total
                return total
            }

        },
        total_flat(inst){
            var mythis = this
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = 0;
            var weight=1;
            if(this.selectedGradeType=='1to5'){weight=5}
            else if(this.selectedGradeType=='1to4'){weight=4}
            console.log('weight ' + weight)
            var allgradecount  = inst.ProcedureStepComponentGrade_InstructorList.length-1
            inst.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.Grade!=null){
                    if(comp.Grade != 'NA' && comp.Grade !='IA'){
                        total = total + Number(comp.Grade)
                        count = count + 1
                    }
                    
                }
                if(comp.Grade=='P' || comp.Grade=='1'){
                    passcount = passcount + 1
                    
                }
                pfcount=pfcount + 1
            }
            inst.instanceHistory_Flat.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                var isactive = mythis.isActive(comp.RelatedID)
                   
                if(isactive){
                       
                    if(comp.Grade!=null){
                        if(comp.Grade != 'NA' && comp.Grade !='IA'){
                            total = total + Number(comp.Grade)
                            count = count + 1
                        }
                        
                    }
                    if(comp.Grade=='P'){
                        passcount = passcount + 1
                        
                    }
                    pfcount=pfcount + 1
                }
            }
          
            avg = total/count;
            outof100=100-total;

            //figure out if we are all full
            inst.isFinalSubmit = (allgradecount<=count)
           
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return passcount + ' out of ' + count;
            }else if(this.selectedGradeSheet.showTotalAsPercent){
                //avg = avg *100 /weight
                avg=(avg * 100) / weight

                this.calcTotal = avg.toFixed(2);
                return 'Avg (%): ' + avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalAsSum){
               // this.calcTotal = total
               inst.calculatedTotal=total
                return  total;
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                //this.calcTotal = avg.toFixed(2);
                inst.calculatedTotal=avg.toFixed(2);
                return  avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalOutOf100){
                //this.calcTotal=outof100multiColumnCount
                inst.calculatedTotal=outof100multiColumnCount
                return  outof100;
            }else{
                //this.calcTotal=total
                inst.calculatedTotal==total
                return total
            }

        },
        isActive(compid){
            var isactive=true
            var list = this.selectedGradeSheet.ProcedureStepList[0].StepComponentList
           
            this.selectedGradeSheet.ProcedureStepList[0].StepComponentList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.ID==compid ){
                
                    isactive= comp.IsActive
                }
            }
            return isactive
        },
        formatDateTime(dt){
            return this.$options.filters.formatDateTime_mini(dt)
        },
        columns(){
            var mult = this.multiColumnCount
            if(this.zoom==mult){
                this.zoom=1
            }else{
                this.zoom=mult
            }
      
        },
         print(divName){
         
            window.print();
            /* var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents; */
        },
        addComment(){
            if(!this.online){this.handleOffline(); return}
            
            this.$refs.addcomment.Update();

        },
        async refreshComments(){
            let req = {
                ProcedureInstance:this.selectedInstanceForComment,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'comments')
            //handle response
            if(response.Successful){
                this.selectedInstanceForComment.AccumulatedComments = response.comments;        
                this.selectedInstanceForComment.HasCommentForThisInstance=response.HasCommentForThisInstance
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async toggleProficient(i){
            if(!this.online){this.handleOffline('prof'); return}

            var success=await this.toggleProficientFlag(i)
            if(!success){
                this.$refs.confirmprof.Show()
            }else{
               
            }

            
            
        },
        openSignModal(docname,docid,doctypename){
            if(!this.online){this.handleOffline(); return}
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },
        grademe(componentId, instance){
            this.selectedComponent=componentId
            this.selectedInstance=instance

            if(this.selectedGradeType=='check'){
                var g = this.getGrade_flat(componentId,instance)
                if(g=='P'){
                    this.setGrade(null)
                }else{
                    this.setGrade('P')
                }
            }else{
                this.$refs.grade15.Update();
            }
           

           
          
        },



        setGrade(grade){
           // console.log('set ' + grade + ' ' + this.selectedComponent)
            var componentId=this.selectedComponent
            //if(grade==''){grade=null}
            this.selectedInstance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                  //  console.log('found it')
              
                    comp.Grade=grade
                    
                    comp.IsDirty=true //mark dirty
                    
                }
            }
            //this.batchKey+=1
            this.$forceUpdate()
            //save to cached instance
            this.saveInstanceToCache()
        },
        
        isGradable(componentId,instance){
            if(this.selectedInstance.IsLocked){return false}
           
            //return instance.ID==this.selectedInstance.ID && this.selectedInstance.IsLocked==false
            var isgradable = true
            instance.instanceHistory.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            isgradable=!comp.IsLocked
                        }
                        
                        
                    }
                }
            }
            return isgradable
        },
        isGradable_flat(componentId,instance){
            if(this.selectedInstance.IsLocked){return false}
           
            //return instance.ID==this.selectedInstance.ID && this.selectedInstance.IsLocked==false
            var isgradable = true
            instance.instanceHistory_Flat.forEach(myFunction3)
            function myFunction3(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    if(comp.Grade!=null){
                        isgradable=!comp.IsLocked
                    } 
                }
            }
            return isgradable
        },
        gradebuttonclass(componentId,instance){
            if(this.isGradable_flat(componentId,instance)){
                if(this.selectedGradeType=='enter'){
                    var grade = this.getGrade_flat(componentId, instance)
                    if(grade<80 && grade!=null){
                        return "gradebuttonfail"
                    }
                }

                return "gradebutton"
            }else{
                return "gradebuttonlocked"
            }
        },
        
        showCheck(componentId, instance){
            var show = false;
            if(this.usingChecks){
                var g = this.getGrade_flat(componentId,instance)
                
                show = (g =='1' || g=='P')
               
            }
            
           return  show
        },
        getGrade(componentId, instance){
            
            var grade;

            var currentInstanceId = instance.ID

            //if we didn't find it in history, look in current
            if(grade==null){
                instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            grade=comp.Grade
                        }
                        
                    }
                }
            }
            
            if(grade==null){
                instance.instanceHistory.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        let comp=arr[index]
                        if(comp.ID!=currentInstanceId){
                            comp.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                            function myFunction2(item, index, arr) {
                                let comp=arr[index]
                                if(comp.RelatedID==componentId){
                                    if(comp.Grade!=null){
                                        grade=comp.Grade
                                       // console.log('getgrade ' + componentId + ' ' + grade)
                                    }
                                    
                                }
                            }
                        }
                        
                    }
            }
            
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
           // console.log('grade to check ' + grade)
            if(grade==undefined){return null}
            if(typeof grade ==='object'){return null}
            if(grade!=null){
                
                if(String(grade).includes('.')){
                    grade = Number(grade).toFixed(0)
                }
            }
            

            return grade
        },
        getGrade_flat(componentId, instance){
            
            var grade;

            var currentInstanceId = instance.ID

            //if we didn't find it in history, look in current
            if(grade==null){
                instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.RelatedID==componentId){
                        if(comp.Grade!=null){
                            grade=comp.Grade
                        }
                        
                    }
                }
            }
            
            if(grade==null){
                instance.instanceHistory_Flat.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        let comp=arr[index]
                        
                        if(comp.RelatedID==componentId){
                            if(comp.Grade!=null){
                                grade=comp.Grade
                                       // console.log('getgrade ' + componentId + ' ' + grade)
                            }
                        }  
                    }
            }
            
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
           // console.log('grade to check ' + grade)
            if(grade==undefined){return null}
            if(typeof grade ==='object'){return null}
            if(grade!=null){
                
                if(String(grade).includes('.')){
                    grade = Number(grade).toFixed(0)
                }
            }
            

            return grade
        },
        async savegrades(issubmit){
            if(this.insave){return}

            
            this.updateAutoTimeItem()
            this.saveTimeDataToStorage_Batch()

            this.responseMessage=null
           
            //if this is a submit and we are offline, no soup
            if(!this.online && issubmit){this.handleOffline(); return}

            //if offline and just save, then OK. just keep cached grades
            if(!this.online){
                this.$emit('gotohome')
                return;
            }

            //if submit and manual, validate the time entries
            if(issubmit){
                if(this.showManualTime){
                    if(this.RequireManualEntry ){
                        if(this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null){
                            this.responseMessage="Please enter course start and end times before submitting"
                            return
                        }
                    }
                    
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    if( sdate > edate){
                        this.responseMessage = "Please enter a course end time AFTER your course start time"
                        return
                    }

                    //if we are doing indiv times...check those
                    let mythis = this
                    
                    if(this.selectedTimeData.isIndividualTime){
                        //console.log('insubmit1')
                        var alltimesfilled=true
                        this.selectedInstanceList.forEach(myFunction3)
                        function myFunction3(item, index, arr) {
                            let currentinst=arr[index]
                           // console.log('currtime ' + currentinst.enteredCourseEndTime)
                          //  console.log( currentinst.enteredCourseEndTime=='')
                            if(!mythis.instHasTimeFilled(currentinst)){
                                alltimesfilled=false
                            }
                        }
                        if(!alltimesfilled){
                            this.responseMessage="Please enter course start and end times for all students before submitting"
                                return
                        }
                    }
                    //main course minutes
                    var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    
                    var courseMinutes = date1-date2
                    courseMinutes= (courseMinutes / (60000));
                    
                    //now go through the instances
                   
                    var toomuchbtw=false
                    var missingbtw =false
                    this.selectedInstanceList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]

                        //if we are doing indiv time...need new course time
                        var indivcourseminutes=courseMinutes
                        if(mythis.showIndivCourseTime){
                            var date3= new Date(mythis.selectedTimeData.enteredCourseDate + ' ' + comp.enteredCourseEndTime)
                            var date4 = new Date(mythis.selectedTimeData.enteredCourseDate + ' ' + comp.enteredCourseStartTime)
                            
                            var indivcourseminutes = date3-date4
                            indivcourseminutes= (indivcourseminutes / (60000));
                        }

                        if( comp.enteredBTWMinutes==-1 && mythis.showIndividualBTW && mythis.showBTW){
                            missingbtw=true
                        }

                        if(indivcourseminutes<comp.enteredBTWMinutes){
                            toomuchbtw=true
                        }
                    }

                    if(missingbtw && this.requireBTW){
                        this.responseMessage="Please select BTW minutes for each student"
                        return
                    }
                    if(toomuchbtw){
                        this.responseMessage="Please enter BTW minutes less than or equal to the total course time for each student."
                        return
                    }

                }
                
            }

            //if we are requiring comments... 
            if(window.REQUIRE_XCHARACTER_COMMENT>0 && issubmit && !this.BatchComponentsAsColumns){
             //   console.log('checkforcommentsforall')
                if(!this.allInstancesHaveComments()){
                    this.responseMessage = "Please enter a comment for each student before submitting"
                    return
                }
            }

            if(this.RequireSignature && this.selectedInstance.signatureFileName==null && issubmit){
                this.responseMessage = "Please sign before submitting"
                return
            }

            //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
            if(this.warnAboutTimeConflicts && issubmit){this.checkForTimeConflicts();return;}

            //if NOT, then go ahead

            this.insave=true
            //PREPARE the DATA. send our total/avg/etc along with it
           // this.selectedInstance.calculatedTotal = this.calcTotal
            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                submit:issubmit,
                timeData:this.selectedTimeData,
                useIndividualTime:this.showIndivCourseTime,
                loggedInUserName:this.loggedInUser.name
            }
             this.$store.dispatch('beforeAPI');
             
            var response = await this.callAPI(req,'saveprocedureinstance_batch')
            //handle response
            if(response.Successful){
                this.insave=false
                   // this.clearDirtyBitInCache()
                    if(issubmit && this.isManualTime==false){
                        this.removeTimeDataForSubmittedInstance(this.selectedInstance.ID)
                    }
                    //all done go home
                    this.$emit('gotohome')
                
                           
            }else{
                this.insave=false
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async checkForTimeConflicts(){
            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                timeData:this.selectedTimeData,
                useIndividualTime:this.showIndivCourseTime
            }
             this.$store.dispatch('beforeAPI');
     
            var response = await this.callAPI(req,'checkprocedureinstancetime_batch')
            //handle response
            if(response.Successful){
                //if no time conflicts, just go ahead and finish the submit
                if(response.timeConflictList.length==0){this.finishsubmitinstortime(); return;}

                //show the conficts
                this.timeConflictList= response.timeConflictList

                let mythis = this
                this.timeConflictList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    comp.evalStartTime=mythis.$options.filters.formatTimeForInput(comp.evalStartTime)
                    comp.evalEndTime=mythis.$options.filters.formatTimeForInput(comp.evalEndTime)
                }

                this.$refs.confirmsubmit.Show()
               
                           
            }
            this.pleaseWait=false; 
        },
        async finishSubmit(){
            //PREPARE the DATA. send our total/avg/etc along with it
            //this.selectedInstance.calculatedTotal = this.calcTotal
            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                submit:true,
                timeData:this.selectedTimeData,
                useIndividualTime:this.showIndivCourseTime,
                loggedInUserName:this.loggedInUser.name
            }
             this.$store.dispatch('beforeAPI');
             
            var response = await this.callAPI(req,'saveprocedureinstance_batch')
            //handle response
            if(response.Successful){
                this.insave=false
                   // this.clearDirtyBitInCache()
                    if( this.isManualTime==false){
                        this.removeTimeDataForSubmittedInstance(this.selectedInstance.ID)
                    }
                    //all done go home
                    this.$emit('gotohome')
                
                           
            }else{
                this.insave=false
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async finishsubmittime(){
            //console.log('finish submit time')
            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                timeData:this.selectedTimeData,
                useIndividualTime:this.showIndivCourseTime
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'savetime_batch')
            //handle response
            if(response.Successful){
                this.inTimeUpdate=false       
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        
        async saveonly(){
            this.updateAutoTimeItem()
            this.saveTimeDataToStorage()


            this.responseMessage=null

            //send our total/avg/etc along with it
          //  this.selectedInstance.calculatedTotal = this.calcTotal
            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstanceList:this.selectedInstanceList,
                submit:false,
                timeData:this.selectedTimeData,
                useIndividualTime:this.showIndivCourseTime,
                loggedInUserName:this.loggedInUser.name
            }
             this.$store.dispatch('beforeAPI');
             
            var response = await this.callAPI(req,'saveprocedureinstance_batch')
            //handle response
            if(response.Successful){
                this.refreshme()
                           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },

        allInstancesHaveComments(){
            var allcomments=true
            this.selectedInstanceList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                
                if((comp.enteredComment==null || comp.enteredComment=='') && !comp.HasCommentForThisInstance){
                    allcomments=false
                 //   console.log('nocomment')
                }
                /* if(comp.HasCommentForThisInstance==false){
                    allcomments=false
                    console.log('nocomment')
                } */
            }
            return allcomments
        },
        async clockOutStudent(){
            let req = {
                    locationId:this.selectedInstance.LocationID,
                    instructorId:this.selectedInstance.InstructorId,
                    personID:this.selectedInstance.StudentID
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockoutstudent')
                //handle response
                if(response.Successful){
                    this.$emit('gotohome')
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
        },
        
          async home(){
            if(this.inTimeUpdate){
                this.responseMessage="Please Save or Cancel your Time Update before closing"
                return
            }
            this.$emit('gotohome')
            
          },
          cancelGrading(){
            this.insave=false
            this.saveTimeDataToStorage()
            this.$emit('gotohome')
          },
          
         //offline
         handleOffline(){
            this.$refs.modalmessage.show()
          
        },


        //TIME DATA
        setTimeDataFromInstanceData(){
            this.selectedTimeData={
                    instanceId:this.selectedInstance.ID,
                    enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                    enteredCourseStartTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseStartTime),
                    enteredCourseEndTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseEndTime),
                    enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                    autoTimeItems:[]
                }
                if(this.selectedTimeData.enteredCourseStartTime==this.selectedTimeData.enteredCourseEndTime){
                    this.selectedTimeData.enteredCourseStartTime=null
                    this.selectedTimeData.enteredCourseEndTime=null
                }
                var mythis = this
                var multiples=false
                this.selectedInstanceList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.enteredCourseStartTime=mythis.$options.filters.formatTimeForInput(comp.enteredCourseStartTime)
                        comp.enteredCourseEndTime=mythis.$options.filters.formatTimeForInput(  comp.enteredCourseEndTime)
                        if(index>0){
                            if(comp.enteredCourseEndTime!=arr[index-1].enteredCourseEndTime)
                            {multiples=true}
                        }
                        if(comp.enteredCourseStartTime==comp.enteredCourseEndTime){
                            comp.enteredCourseStartTime=null
                            comp.enteredCourseEndTime=null
                        }
                    }
                    if(multiples){
                        this.showIndivCourseTime=true
                    }
        },
        startGrade(){
            if(!this.selectedInstance.IsLocked && this.selectedInstance.employeeTimeCardItemID_Course==0){
                this.getInstanceTimeDataFromStorage()
            }else{
                this.setTimeDataFromInstanceData()
            }

            //we are going into grade mode. place to mark time if needed
            if(this.isManualTime){
                //MANUAL
                
            }else{
                //AUTO
                this.selectedTimeData.autoTimeItems.push(this.getNewAutoTimeItem())
            }
        },
       
        getInstanceTimeDataFromStorage(){
           // console.log('gettimedatafromstorage')
            var instId = this.selectedInstance.ID

            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData

            if(tmptime===undefined){
               // console.log('new time data')
                this.timeData=[this.getNewTimeData_Batch()] //get it and add to the list
                this.selectedTimeData =  this.timeData[0] //set it as selected
                return
            }

            try{

                this.timeData = JSON.parse(tmptime);
                var foundTimeData=null
                this.timeData.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.instanceId==instId){
                        foundTimeData=comp
                    }
                }
               // console.log('found time?' + foundTimeData)
                //didn't find it, so we need to make/add a new one
                if(foundTimeData==null){
                  //  console.log('new time data2')
                    var newTimeData = this.getNewTimeData_Batch()
                    this.timeData.push(newTimeData)
                    this.selectedTimeData = newTimeData
                }else{
                    //found it!
                    this.selectedTimeData = foundTimeData

                    //if we have instance specific data
                    if(this.selectedTimeData.batchInstances.length>0){
                        this.showIndivCourseTime = this.selectedTimeData.isIndividualTime

                        let mythis = this
                        this.selectedTimeData.batchInstances.forEach(myFunction2)
                        function myFunction2(item, index, arr) {
                            let cacheinst=arr[index]
                            let cacheinstId=cacheinst.ID
                            //now find it in my current inst data
                            mythis.selectedInstanceList.forEach(myFunction3)
                            function myFunction3(item, index, arr) {
                                let currentinst=arr[index]
                                if(currentinst.ID==cacheinstId){
                                    //upate the current inst with what was in cache
                                    currentinst.enteredBTWMinutes=cacheinst.enteredBTWMinutes
                                    currentinst.enteredCourseStartTime=cacheinst.enteredCourseStartTime
                                    currentinst.enteredCourseEndTime=cacheinst.enteredCourseEndTime
                                    currentinst.enteredComment=cacheinst.enteredComment
                                    currentinst.enteredBreak=cacheinst.enteredBreak
                                    currentinst.HasCommentForThisInstance=currentinst.enteredComment!=null && currentinst.enteredComment!=''
                                }
                            }
                            
                        }
                    }

                }
            } catch(error){
                this.timeData=[this.getNewTimeData_Batch()] //get it and add to the list
                this.selectedTimeData =  this.timeData[0] //set it as selected
                return
            }
        },
        
        
        
        showcomments(inst){
            if(!this.online){this.handleOffline(); return}
            this.selectedInstanceForComment=inst
            this.$refs.comment.show(inst)
        }
      },
      mounted(){
       // this.multColumnOverride= localStorage.multColumnOverride
       // this.zoom=this.multiColumnCount
       if(!this.selectedInstance.IsLocked){
        if(this.selectedGradeSheet.batch_SelectComponents){
            this.$refs.components.Update()
        }else{
            this.showAllComponents=true
        }
       
       }
        
      }
  }
  </script>
  
  <style>
  ::-webkit-scrollbar{

-webkit-appearance: none;
width: 7px;

}

::-webkit-scrollbar-thumb {

border-radius: 4px;
background-color: rgba(0,0,0,.5); 
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  .gradecheckimage{
    height:20px;
  }
  .gradeboxlabel{
    margin:0px;
  }
  .gradeDiv{
    
    display:grid;
    grid-template-rows: 170px auto auto 100px;
  }
  .topDiv{
    display:grid;
    grid-template-columns: 300px auto 180px;
    
  }
  .gradesteps{
        padding:5px;
        background-color: var(--frame-color);
    }
  .commentlabel{
    margin:10px;
    padding: 0px 0px 0px 0px;
    height:90px;
    overflow-x:hidden;
    overflow-y:scroll;
  }
  .vertscroll{
    overflow-x:hidden;
    overflow-y:scroll;
    height:142px;
  }
  .leftborder{
    border-left:1px solid gray;
    margin-bottom:10px;
  }
  .rightborder{
    border-right:1px solid gray;
    margin-bottom:10px;
  }
  .total{
    text-align: left;
    font-size:larger;
    font-weight:bold;
  }
  .studentinfo{

    
  }
  .comments{
    background-color:var(--frame-color);
    margin:0px 10px 10px 10px;
    
  }
  .studentinfoandpic{
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin:0px 0px 0px 0px;
  }
.img2{
    height:50px;
    margin:2px;
}
.img2big{
    height:75px;
    margin:2px;
}
  .gradebutton{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    border:3px solid green;
    font-size:larger;
  }
  .greenlabel{
    color:green;
  }
  .gradebuttonlocked{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    color:black;
    border:1px solid gray;
    font-size:larger;
  }
  .gradebuttonfail{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    border:3px solid red;
    font-size:larger;
  }
    .headerlabel{
    
        margin:0px;
        /* font-size:larger; */
    }
    .headerlabelplaceholder{
        height:50px;
        margin:0px;
    }
    .steplabel{
        font-size:larger;
        margin:0px 0px 0px 0px ;
        padding: 5px 0px 5px 0px;
        font-weight:bold;
        background-color:#AFBCCE;
        width:100%;
    }
    .componentlabelstu{
        margin:10px 0px 0px 0px;
    }
    
    .studentname{
        margin:5px 5px 10px 5px;
        color:var(--text-color);
    }
    .larger{
        font-size:larger;
    }
    .step{
        
   }
   .scrolldiv{
    height:350px;

    overflow-x:hidden;
    overflow-y:scroll;
   }
   .scrolldivbatch{
    height:480px;
    width:95vw;
    overflow-x:scroll;
    overflow-y:scroll;
   }
  .component{
    border-bottom: 1px solid lightgray;
    margin-bottom:0px;
    /* margin-bottom:5px; */
    
  }
  .componentdiv{
    display:grid;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{

  }
  .graderow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px repeat(9,100px);
  }
  .graderowstu{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px repeat(12,100px);
  }
  .fixedcolstu{
    
    width:100px;
    padding:0px;
    margin:0px;
  }
  .fixedcolstuwide{
    text-align:left;
    width:250px;
    padding:0px;
   
  }
  .graderowmulti{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px; 
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px 60px;
  }


  .headerrow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    display:grid;
    grid-template-columns: 250px repeat(9,100px);
  }
  .headerrowstu{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    display:grid;
    grid-template-columns: 250px repeat(12,100px);
  }
  .widebutton{

    border: none;
    border-radius:5px;
    width:180px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
    text-decoration: none;

  }
  .narrowbutton{

    border: none;
    border-radius:5px;
    width:50px;
    height:30px;
    padding:0px;
    font-size: medium;
    margin:5px;
    text-decoration: none;

}
.recordbutton{

border: none;
border-radius:5px;
width:70px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbutton{

border: none;
border-radius:5px;
width:80px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbuttonprof{
    border: none;
border-radius:5px;
width:75px;
height:35px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonadd{
    border: none;
border-radius:5px;
width:105px;
height:35px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonwide{
    border: none;
border-radius:5px;
width:120px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonwider{
    border: none;
border-radius:5px;
width:185px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.filterfield{
    height:30px;
    margin:5px;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
}
.btwfield{
    height:30px;
    width:30px;
}
.btwdropdown{
    height:30px;
    width:60px;
}
.finishbutton{

border: none;
border-radius:5px;
width:150px;
height:40px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.truckroutelabels{
    margin:0px 20px 0px 0px;
    height:25px;
}
.truckroutediv{
    max-height:25px;
}

.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}

.manualTimeLabel{
    font-size:medium;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.manualTimeLabel2{
    font-size:medium;
    margin:12px 0px 0px 0px;
    color:var(--text-color);
}
.fixedcolumnlabel{
    width:80px;
    text-align: left;
}
.fixedcolumnlabelwide{
    min-width:150px;
    text-align: left;
}
.headerdiv{
    display:grid;
    grid-template-rows: 60px auto auto auto;
    
}
.headerdivitem40{
    height:40px;
}
.headerdivitem50{
    height:50px;
}
.headerwithmargin{
    margin:0px 0px 40px 0px;
}
.toBottom{
    display:grid;
    grid-template-rows: auto 50px;
}
.center{
    justify-items: center;
    text-align: center;
}
.sig{
    height:25px;
}
.toparea{
    display:grid;
    grid-template-columns: 200px 800px 200px ;
    margin:0px 0px 0px 0px ;
    padding:0px;
}
.fixwidth{
    width:96vw;
}
.studenttimegrid{
    display:grid;
    grid-template-columns: 2fr 2fr 20px 2fr 80px auto;
    text-align: left;
}
.topmargin{
    margin:10px 0px 0px 0px;
}
  </style>

/* if(componentId==5){
    this.selectedGradeType='check'
    var g = this.getGrade(componentId,instance)
    if(g=='P'){
        this.setGrade(null)
    }else{
        this.setGrade('P')
    }
    
    return;
}

switch (componentId) {
    case 1:
        this.selectedGradeType='1to5'
        break;
    case 2:
        this.selectedGradeType='1to4'
        break;
    case 3:
        this.selectedGradeType='pf'
        break;
    case 4:
        this.selectedGradeType='enter'
        break;
    
        

    default:
        break;
} */

/* let req = {
    ProcedureInstance:this.selectedInstance,
    autoStartStopTime:!this.isManualTime
}
 this.$store.dispatch('beforeAPI');
var response = await this.callAPI(req,'canceltime')
//handle response
if(response.Successful){
    this.$emit('gotohome')           
}else{
    this.responseMessage = response.Message
}
this.pleaseWait=false;  */