<template>
    <div>
        <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
        <!-- <smallbutton @click="Update()" :toplabel="updateText" :class="buttonclass"/> -->
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{ selectedInstance.StudentName }}</label>
                
                <div class="centered" >
                    <!-- <p class="studentinfolabel larger">{{ selectedInstance.StudentName }}</p> -->
                        <!-- <p class="studentinfolabel ">{{ selectedInstance.Name }}</p> -->
                        <!-- <p class="studentinfolabel ">{{ selectedInstance.LocationName }}</p> -->
                        
                       <!--  <p class="studentinfolabel " v-if="selectedInstance.truckNumber!=null">Truck: {{ selectedInstance.truckNumber }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.routeNumber!=null">Route: {{ selectedInstance.routeNumber }}</p> -->
                        <p class="studentinfolabel " v-if="selectedInstance.AccumulatedTime_Course!=''">Accum. Class Time: {{ selectedInstance.AccumulatedTime_Course }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.AccumulatedTime_BTW!=''">Accum. BTW Time: {{ selectedInstance.AccumulatedTime_BTW }}</p>
                        <!-- <p class="studentinfolabel ">Inst: {{ selectedInstance.InstructorName }}</p> -->
                    <br>
                    <p class="commentlabel">Previous comments: {{ existingComment }}</p>
                    
                    <div class="vertFlex" v-if="!selectedInstance.IsLocked">
                        <p >Add New Comment</p>
                        <select name="" id="" class='entrySelect' required v-if="useCommentList" v-model="commentId" @change="selectComment()" >
                            <option v-for="c in commentList" :key="c.id" :value="c.id" >
                                {{c.name}}
                            </option>
                        </select>
                        <textarea ref="commentbox" class="commentinput" v-model="enteredComment" v-if="showCommentText"></textarea>
                        
                    </div>
                    <div >
                            <!-- <smallbutton  @click="save()" toplabel="Save" /> -->
                            <smallbutton  @click="save()" toplabel="Close"/>
                        </div>               
                </div>
                
                <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
             
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import modalmessage from '../controls/modalmessage.vue'
    export default {
        name:'comment',
        components:{
            smallbutton,
            actionmodal,
            modalmessage
        },
        props:{
            
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                existingComment:null,
                selectedInstance:null,
                enteredComment:'',
                commentId:-1,
                isCustom:false
            }
        },
        computed:{
            ...mapFields([
                'commentList',
                'online'
            ]),
            useCommentList(){
                if(window.USE_COMMENT_LIST==undefined){return false}
                else{return window.USE_COMMENT_LIST}
            },
            showCommentText(){
                return !this.useCommentList || this.isCustom
            }
        },
        methods:{
           selectComment(){
            var comment=null
            var id = this.commentId
            this.commentList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.id==id){
                        comment=inst.name
                    }
                }
            console.log('selcomment')
            if(comment=='Custom'){
                this.isCustom=true
            }else{
                this.isCustom=false
                this.enteredComment=comment
            }
            
           },
           showComment(){
            var comment=this.enteredComment
            var id =-1
            var customid
            if(this.useCommentList){
                this.commentList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.name==comment){
                        id=inst.id
                    }else if(inst.name=='Custom'){
                        customid=inst.id
                    }
                }
            }
            
            this.commentId=id
            if(id==-1 && comment!=null){this.commentId=customid;this.isCustom=true}
           },
           show(inst){
            this.selectedInstance=inst
            this.existingComment = inst.AccumulatedComments
            this.enteredComment=inst.enteredComment
            this.commentId=-1
            //this.enteredComment=''
            this.showComment()
            this.$refs.mymodal.openModal()
            this.$nextTick(()=>{
                this.$refs.commentbox.focus()
            })
           },
           
            cancelupdate(){
                this.$refs.mymodal.closeModal()
            
            },
          
            save(){
                if(!this.online){this.handleOffline(); return}
                var comment 
                //if(this.existingComment!=null){comment= this.existingComment + ' | ' + this.enteredComment}
                //else{comment=this.enteredComment}
                //this.selectedInstance.enteredComment=this.enteredComment
                this.$emit('savecomment',this.selectedInstance.ID, this.enteredComment)
                this.$refs.mymodal.closeModal()
                //this.saveComment(comment)

            },
            async saveComment(comment){
                //save back the edited one local
                this.selectedInstance.OverallGradeList_Instructor[0].Comment = comment

                let req = {
                    ProcedureInstance:this.selectedInstance,
                    comment:comment
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'savecomment')
                //handle response
                if(response.Successful){
                    this.existingComment=comment
                    this.$emit('refreshcomments')
                    this.$refs.mymodal.closeModal()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
            },
            
            //offline
            handleOffline(){
                this.$refs.modalmessage.show()
                //console.log('offline... no soup for you')
            },
           
        }
    }
    </script>
    
    <style>
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    .selectgradebutton{
    height:70px;
    width:70px;
    background-color: var(--frame-color);
    border:1px solid gray;
    font-size:larger;
    margin:5px;
  }
  .gradelabel{
    margin: 25px 0px 0px 10px;
  }
  .commentinput{
    text-align: left;
    width:97%;

  }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    