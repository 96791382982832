<template>

                <div>
                    <label  >Assessment Date</label>
                    <select name="" id="" class='filterselect' v-model="gradeDate" >
                        <option v-for="s in cleanDateList" :key="s.enteredDate" :value="s.enteredDate" >
                            {{s.enteredDate}}
                        </option>
                    </select>
                    <select name="" id="" class='filterselect' v-model="studentId" required  >
                        <option v-for="s in studentList" :key="s.id" :value="s.id" >
                            {{s.name}}
                        </option>
                    </select>
                    
                    <smallbutton toplabel="Get Data" @click="getsheets" buttonclass="buttonprimary innerbutton" />
                    <smallbutton toplabel="Print" @click="print('printableArea')" buttonclass="buttonprimary innerbutton" />
                    <smallbutton toplabel="Close" @click="$emit('close')" buttonclass="buttonprimary innerbutton"/>
                </div>
                <div>
                    <label class="pleasewait" v-if="pleaseWait">Please wait...</label>
                </div>
                <div  id="printableArea" >
                    <input class="margin10" type="button" onclick="window.print()" value="Print" v-if="inPrint" />
                    <div v-for="pi in selectedInstanceList" :key="pi.id" :value="pi.id" class="gradeDiv">
                       
                            <div class="topDiv">
                            
                                <div class="leftjustified rightborder">
                                    <div class="studentinfoandpic">
                                        <img :src="imagePathPerson" class="img" v-if="ShowStudentImage"/>
                                        <div class="">
                                            <p class="studentinfolabel larger">{{ pi.StudentName }}</p>
                                            <p class="studentinfolabel ">{{ pi.Name }}</p>
                                            <p class="studentinfolabel ">{{ pi.LocationName }}</p>
                                            
                                            <p class="studentinfolabel " v-if="pi.truckNumber!=null">Truck: {{ pi.truckNumber }}</p>
                                            <p class="studentinfolabel " v-if="pi.routeNumber!=null">Route: {{ pi.routeNumber }}</p>
                                            <p class="studentinfolabel " v-if="pi.AccumulatedTime_Course!=''">Accum. Class Time: {{ pi.AccumulatedTime_Course }}</p>
                                            <p class="studentinfolabel " v-if="pi.AccumulatedTime_BTW!=''">Accum. BTW Time: {{ pi.AccumulatedTime_BTW }}</p>
                                            <p class="studentinfolabel ">Inst: {{ pi.InstructorName }}</p>
                                            <p class=" redlabel" v-if="pi.criticalFailureCount>0">{{ CriticalMissText }}: {{ pi.criticalFailureCount }}</p>
                                            <img :src="pi.signatureFileName" class="sig"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="comments leftjustified">
                                    
                                    <p class="commentlabel2">Previous comments: {{ pi.AccumulatedComments }}</p>
                                    
                                </div>
                                
                                
                            </div>
                        
                            
                            <div class="gradesteps">
                                <div class="horizFlexStretch">
                                    <div>
                                    
                                    
                                    </div>
                                    <div v-if="pi.isFailure">
                                        <p class="responsemessage">{{FailedText}}</p>
                                    </div>
                                    <div class="horizFlex wrap" v-if="isManualTime"> 
                                        
                                        <p class="manualTimeLabel">Course Date/Time</p>
                                        <input class="filterfield" type="date" v-model="pi.enteredCourseDate" :disabled="disableTime">
                                        <input class="filterfield" type="time" v-model="pi.enteredCourseStartTime" step="900" 
                                            :disabled="disableTime" v-if="showManualTime_Files(pi) && !showTimeAs24">

                                        <div class="time24" v-if="showManualTime_Files(pi) && showTimeAs24">
                                            <vue-timepicker input-width="80px" v-model="starttime24"  ></vue-timepicker>

                                        </div>
                                        
                                        <p class="manualTimeLabel2" v-if="showManualTime_Files(pi)">to</p>
                                        <input class="filterfield" type="time" v-model="pi.enteredCourseEndTime" step="900" 
                                            :disabled="disableTime" v-if="showManualTime_Files(pi) && !showTimeAs24">

                                        <div class="time24" v-if="showManualTime_Files(pi) && showTimeAs24">
                                        <vue-timepicker  input-width="80px"  v-model="endtime24" ></vue-timepicker>
                                        </div>

                                        <p class="manualTimeLabel" v-if="showManualTime_Files(pi) && showBTW">BTW Minutes</p>
                                        <input class="filterfieldshort" v-model="pi.enteredBTWMinutes" :disabled="disableTime" v-if="showManualTime_Files && !showBTWDropDown && showBTW">

                                        <select name="" id="" class='btwdropdown'  v-if="showManualTime_Files(pi) && showBTWDropDown && showBTW" v-model="selectedBTWMinutes" >
                                            <option v-for="c in btwMinutesList" :key="c.id" :value="c.id" >
                                                {{c.name}}
                                            </option>
                                        </select>



                                
                                    </div>
                                    <!-- <smallbutton :toplabel="proficiencyLabel" @click="toggleProficient" :buttonclass="proficiencyClass" v-if="ShowProficiency"/> -->
                                    <div class="vertFlex" v-if="showProficiencyForThisEval_Files(pi) && ShowProficiency">
                                        <!-- <confirm :buttonclass="proficiencyClass" :showButton="paramTrue" body="Do you want to update the proficiency?" :title="proficiencyLabel"
                                                @yes="toggleProficient" /> -->
                                        <p>{{ proficiencyInstructor(pi) }}</p>
                                    </div>
                                    
                                </div>
                                
                
                            
                                <!-- steps and components -->
                                <div v-for="s in procedureSteps(pi)" :key="s.ID" :value="s.ID" class="step" >
                                    <div > 
                                        <!--class="graderow" -->
                                        <p class=" steplabel">{{s.Name}}</p>
                                    </div>
                                    
                                    <div :class="componentclass">
                                            <div v-for="c in s.StepComponentList" :key="c.ID" :value="c.ID" class="component">
                                                <div v-if="c.IsActive">
                                                
                                                    <div class="graderowmulti" v-if="zoom>1">
                                                        <p class="componentlabel" v-if="!componentHasCheckOff(c)">{{getComponentName(c)}}</p>
                                                        <div v-if="componentHasCheckOff(c)" class="horizFlex">
                                                            <p class="componentlabel" >{{getComponentName(c)}}</p>
                                                            <wheels :checkOffData="getCheckOffData(c.ID,pi)" v-if="c.checkOffStyle=='wheels'" @checkoff="checkoff" :componentId="c.ID" :instance="pi"/>
                                                            <pins :checkOffData="getCheckOffData(c.ID,pi)" v-if="c.checkOffStyle=='pins'" @checkoff="checkoff" :componentId="c.ID" :instance="pi"/>
                                                            <pins5 :checkOffData="getCheckOffData(c.ID,pi)" v-if="c.checkOffStyle=='pins5'" 
                                                                @checkoff="checkoff" :componentId="c.ID" :instance="pi"/>
                                                            <flashers :checkOffData="getCheckOffData(c.ID,pi)" v-if="c.checkOffStyle=='flashers8'" 
                                                                @checkoff="checkoff" :componentId="c.ID" :instance="pi"/>
                                                            <reflectives :checkOffData="getCheckOffData(c.ID,pi)" v-if="c.checkOffStyle=='diamond4'" 
                                                                @checkoff="checkoff" :componentId="c.ID" :instance="pi"/>
                                                        </div>
                                                        
                                                        <button v-if="!componentHasCheckOff(c)" @click="grademe(c.ID,pi)" :class="gradebuttonclass(pi)" :disabled="!isGradable(pi)">
                                                            <!-- <div class="gradeinner"> -->
                                                                <p class="gradeboxlabel" v-if="!showCheck(c.ID,pi)">{{ getGrade(c.ID,pi) }}</p>
                                                                <!-- v-if="!usingChecks" -->
                                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,pi)">
                                                            <!-- </div> -->
                                                        </button>
                                                        <button v-if="componentHasCheckOff(c)"  :class="gradebuttonclass(pi)" :disabled="!isGradable(pi)">
                                                            <!-- <div class="gradeinner"> -->
                                                                <p class="gradeboxlabel" v-if="!showCheck(c.ID,pi)">{{ getGrade(c.ID,pi) }}</p> 
                                                                <!-- v-if="!usingChecks" -->
                                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,pi)">
                                                            <!-- </div> -->
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                            
                                            </div>              
                                    </div>
                                    
                                    
                                </div>
                                <div class="total" v-if="showTotal_Files(pi)">
                                    <p>{{ total(pi) }}</p>
                                </div>
                                
                            </div>
                       
                    </div>

            </div>

</template>
  
  <script>

  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import modalmessage from '../controls/modalmessage.vue'
  import confirm from '../actions/confirm.vue'
  import wheels from './wheels.vue'
  import pins from './pins.vue'
  import pins5 from './pins5.vue'
  import flashers from './flashers.vue'
  import reflectives from './reflectives.vue'
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
import evalhelper  from '../../services/evalhelper.js'
  export default {
      name:'gradesheetsd',
      mixins:[datamethods, format,evalhelper],
      components:{
          smallbutton,
          linkbutton,
       modalmessage,
       confirm,
       wheels,
       pins,
       pins5,
       flashers,
        reflectives,
     VueTimepicker,
    
     
   
      },
      props:{
        /* studentId:{
            type:Number,
        },
        gradeDate:{
            type:Date
        }, */
        studentList:[],
        dateList:[]  
          
        },
      data(){
          return{
            studentId:0,
            gradeDate:null,
            cleanDateList:[],

            inPrint:false,
            selectedInstanceList:{},
            procedureList:[],
        

            lastWasPass:false,

           starttime24:'',
           endtime24:'',

            zoom:1,

            selectedComponent:0,
            
            

            paramTrue:true,
            paramFalse:false,   
            listenersConnected:true,
            
           
            proficient:false,
            calcTotal:0,
            multColumnOverride:-1,

            inTimeUpdate:false,
            confirmSubmitText:'This evaluation fails the requirements.  Do you want to submit as failure?',
            confirmSubmitTitle:'Submit Failure',

            
            selectedBTWMinutes:0,
            first:null,
            last:null
          }
      },
      computed:{
        CriticalMissText(){
            var txt = 'Critical Miss'
            if(window.CRITICAL_TEXT_OVERRIDE!=undefined){
                txt = window.CRITICAL_TEXT_OVERRIDE
            }
            return txt
        },
        showTimeAs24(){
            if(window.SHOW_TIME_AS_24==undefined){
                return false
            }else{
               return window.SHOW_TIME_AS_24
            }
        },

        FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
        showBTWDropDown(){
            
            if(window.SHOW_BTW_DROP_DOWN==undefined){
                return false
            }else{
               return window.SHOW_BTW_DROP_DOWN
            }
        },
        
        disableTime(){
            return true
        },
        ColumnsLabel(){
            if(this.zoom==1){return "View Today"}
            else{return "View History"}
        },
        selectedInstanceComment(){
            if(this.pi.OverallGradeList_Instructor.length>0){
                return this.pi.OverallGradeList_Instructor[0].Comment
            }
            
        },
        checkImage(){
            return require("@/assets/check.png")
        },
        
        multiColumnCount(){
            var cols=3
            /* if(window.DEFAULT_COLUMN_COUNT!=undefined){cols=window.DEFAULT_COLUMN_COUNT}

            if(this.multColumnOverride>0){cols = this.multColumnOverride} */
            return cols
        },
        ShowSignature(){
            if(window.SHOW_SIGNATURE==undefined){return false}
            else{return window.SHOW_SIGNATURE}
        },
        RequireManualEntry(){
            if(window.REQUIRE_MANUAL_ENTRY==undefined){return false}
            else{return window.REQUIRE_MANUAL_ENTRY}
        },
        ShowStudentImage(){
            if(window.SHOW_STUDENT_IMAGE==undefined){return false}
            else{return window.SHOW_STUDENT_IMAGE}
        },
        
        ShowProficiency(){
            if(window.SHOW_PROFICIENCY==undefined){return false}
            else{return window.SHOW_PROFICIENCY}
        },
        
        proficiencyClass(){
            if(this.pi.studentProficiency==undefined){return "innerbuttonprof"}
            if(this.pi.studentProficiency.StudentIsProficient_ThisItem==true){
                return "buttongreen innerbuttonprof"
            }else{return "buttonred innerbuttonprof"}
        },
        proficiencyLabel(){
            var lbl
            if(this.pi.studentProficiency==undefined){return ""}
            if(this.pi.studentProficiency.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}

            if (this.pi.Name.toLowerCase().includes('range')){
                lbl=lbl + ' on Range'
            }else if (this.pi.Name.toLowerCase().includes('road')){
                lbl=lbl + ' on Road'
            }else if (this.pi.Name.toLowerCase().includes('hazmat')){
                lbl = lbl + ' on Hazmat'
            }
            return lbl
        },
        
        
        setproficiencyInstructor(){
            var lbl
            if (this.pi.Name.toLowerCase().includes('range')){
                this.pi.studentProficiency.Student_Range_Instructor= this.loggedInUser.user_personId

            }else if (this.pi.Name.toLowerCase().includes('road')){
                this.pi.studentProficiency.Student_Road_Instructor= this.loggedInUser.user_personId
            }else if (this.pi.Name.toLowerCase().includes('hazmat')){
                this.pi.studentProficiency.Student_Hazmat_Instructor= this.loggedInUser.user_personId
            }
            return lbl
        },
        componentclass(){
            return "componentdiv zoom" + this.zoom
        },
       
          //image
          imagePathPerson(){
            return this.selectedInstanceList[0].studentImageFileName
              //var path = this.employeecurrentPerson.personImageFileName;
              //if(path==''){return}
              //return path
              /* console.log(this.imageBasePath + path)
              return this.imageBasePath + path; */
          },
          usingChecks(){
            return this.selectedGradeType=='check'
        },
        usingPassFail(){
            return this.selectedGradeType=='pf'
        },
          ...mapFields([
              'loggedInUser',
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'isManualTime',
             'online',
             'responseMessage',
             'instructorList',
             'btwMinutesList'
          ]),
          AllowNAAsGrade(){
                if(window.ALLOW_NA_AS_GRADE==undefined){return false}
                else{return window.ALLOW_NA_AS_GRADE}
            },
            FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
    
      },
      
      methods:{
        proficiencyInstructor(pi){
            var lbl
            if(pi.studentProficiency==undefined){return ""}
            if(pi.studentProficiency.StudentIsProficient_ThisItem==false){
                lbl= ""
            }else if (pi.Name.toLowerCase().includes('range')){
                let id=this.pi.studentProficiency.Student_Range_Instructor
                
                lbl=this.getInstructorName(id)
            }else if (pi.Name.toLowerCase().includes('road')){
                let id=pi.studentProficiency.Student_Road_Instructor
                lbl=this.getInstructorName(id)
            }else if (pi.Name.toLowerCase().includes('hazmat')){
                let id=pi.studentProficiency.Student_Hazmat_Instructor
               // console.log('inst id ' + id)
                if(id==null){return ''}
                lbl = this.getInstructorName(id)
            }
            return lbl
        },
        Show(){
            this.makeDateList()
            this.gradeDate=this.cleanDateList[0].enteredDate
            this.studentId=this.studentList[0].id
            //this.getsheets()
           // this.$refs.mymodal.openModal()
        },
        makeDateList(){
            let mythis = this
            this.dateList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                let fixed = mythis.$options.filters.formatDate(comp.enteredDate)
                let newdate = {enteredDate:fixed}
                var found=false

                mythis.cleanDateList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    let comp=arr[index]
                    if(comp.enteredDate==newdate.enteredDate){
                        found=true
                    }
                }
                if(!found){
                    mythis.cleanDateList.push(newdate)
                }
                
            }
            this.first = this.cleanDateList[0].enteredDate
            this.last = this.cleanDateList[this.cleanDateList.length-1].enteredDate
            //add all
            var all = { enteredDate:'All: ' + this.first + '-' + this.last}
                 this.cleanDateList.unshift(all)
        },
        getProcedure(id){
            var proc=null
            this.procedureList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.id==id){
                    proc=comp
                }
                
            }
            return proc
        },
        procedureSteps(pi){
            var steps = []
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return steps}
            return proc.ProcedureStepList
        },
        showTotal_Files(pi){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return false}
            return proc.showTotal
        },
        showManualTime_Files(pi){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return false}
            return proc.showTimeOnManualEntry
        },
        showBTW(){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return false}
            return proc.showBTW
        },
        showProficiencyForThisEval_Files(pi){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return false}
            return proc.showProficiency
        },
        total(pi){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            
            var showTotalAsSum = true
            var showTotalAsAvg =false
            var showTotalOutOf100=false
            var showTotalAsPercent = false

            var weight=1;
            if(this.selectedGradeType=='1to5'){weight=5}
            else if(this.selectedGradeType=='1to4'){weight=4}

            if(proc!=null){
                showTotalAsSum=proc.showTotalAsSum
                showTotalAsAvg=proc.showTotalAsAvg
                showTotalOutOf100=proc.showTotalOutOf100
                showTotalAsPercent=proc.showTotalAsPercent
            }
            

            console.log('gettotal')
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = -1;
            pi.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.Grade!=null){
                    if(comp.Grade != 'NA' && comp.Grade !='IA'){
                        total = total + Number(comp.Grade)
                        count = count + 1
                    }
                    
                }
                if(comp.Grade=='P' || comp.Grade=='1'){
                    passcount = passcount + 1
                    
                }
                pfcount=pfcount + 1
            }
            avg = total/count;
            outof100=100-total;

            //console.log(avg)
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType_Files(pi)=='pf' || this.selectedGradeType_Files(pi)=='check'){
                return 'Passed ' + passcount + ' out of ' + count;
            }else if(showTotalAsPercent){
                //avg = avg *100 /weight
                avg=avg * 100 / weight

                this.calcTotal = avg.toFixed(2);
                return 'Avg (%): ' + avg.toFixed(2);
            }else if(showTotalAsSum){
                this.calcTotal = total
                return 'Total: ' + total;
            }else if(showTotalAsAvg){
                this.calcTotal = avg.toFixed(2);
                return 'Avg: ' + avg.toFixed(2);
            }else if(showTotalOutOf100){
                this.calcTotal=outof100multiColumnCount
                return 'Out of 100: ' + outof100;
            }else{
                this.calcTotal=total
                return total
            }

        },
        selectedGradeType_Files(pi){
            var proc=this.getProcedure(pi.ProcedureSequenceId)
            if(proc==null){return '1to5'}
            if (proc.gradeType==null){
                return '1to5'
            }else{return proc.gradeType}
                
        },
        async getsheets(){
            var start=this.gradeDate
            var end=this.gradeDate
            if(this.gradeDate.includes('All')){
                start=this.first
                end=this.last
            }
            let req = {
                ProcedureFilter:{
                    StudentID:this.studentId,
                    InstructorID:-1,
                    LocationID:-1,
                    StartDateTime:start,
                    EndDateTime:end,
                    IncludeOnlyActiveStatus:false,
                    IncludeOnlyCompleteStatus:false,
                    IncludeOnlyIncompleteStatus:false,
                    ProcedureInstanceStatusID:-1,
                    includeAllUnsubmitted:false,
                    procedureSequenceId:-1,
                    productId:-1,
                    studentGroupId:-1
                    
                }
                
            }


             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedureinstance_bystudentdate')
            //handle response
            var mythis = this
            if(response.Successful){
                 this.selectedInstanceList=response.ProcedureInstanceList
                this.procedureList = response.ProcedureSequenceList

                let mythis = this
                 this.selectedInstanceList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                        let comp=arr[index]
                        comp.enteredCourseDate=mythis.$options.filters.formatDateForInput(comp.enteredCourseDate)
                        if(! mythis.showTimeAs24){
                            comp.enteredCourseStartTime=mythis.$options.filters.formatTimeForInput(comp.enteredCourseStartTime),
                            comp.enteredCourseEndTime=mythis.$options.filters.formatTimeForInput(comp.enteredCourseEndTime)
                        }
                       
                    }

                




                this.pleaseWait=false; 

               
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 

          },
        
        historyheaderlabelclass(h){
            if(h.isFailure){return "headerlabelfailed"}
            else{return "headerlabel"}
        },
        getComponentName(c){
            var name =c.Name
            if(c.isMajor){name= "* " + name}
            return name
        },
        componentHasCheckOff(c){
            return c.checkOffStyle!=null
        },
        
        
       
        getInstructorName(id){
          //  console.log('instid2 ' + id)
       
            var name
            this.instructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.id==id){
                    name=comp.name
                }
            }
            return "by:" + name
        },
        
        formatDateTime(dt){
            return this.$options.filters.formatDateTime_miniwithtime(dt)
        },
        columns(){
            var mult = this.multiColumnCount
            if(this.zoom==mult){
                this.zoom=1
            }else{
                this.zoom=mult
            }
      
        },
         print(divName){
            window.print();
           
            /* this.inPrint=true

            this.$nextTick(()=>{
                
                // Get HTML to print from element
                const prtHtml = document.getElementById(divName).innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
                }

                // Open the print window
                const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    ${prtHtml}
                </body>
                </html>`);

                this.inPrint=false
            }); */
        },
        
       
        
        
      
        setGrade(grade, checkOffData=null){
          //  console.log('setgrade ' + grade)
            var componentId=this.selectedComponent
            var instId = this.pi.ID

            this.pi.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    comp.Grade=grade
                    comp.checkOffData=checkOffData
                    comp.IsDirty=true //mark dirty
                }
            }
            //also save to instance list that matches!
            var thisInst;
            this.selectedInstanceList.forEach(myFunction3)
            function myFunction3(item, index, arr){
                if(arr[index].ID==instId){
                    thisInst=arr[index]
                }
            }
            thisInst.ProcedureStepComponentGrade_InstructorList.forEach(myFunction4)
            function myFunction4(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    comp.Grade=grade
                    comp.checkOffData=checkOffData
                    comp.IsDirty=true //mark dirty
                }
            }


            //save to cached instance
            this.saveInstanceToCache()
        },
        isGradable(instance){
            return false
           // console.log(instance.ID + ' ' + this.pi.ID)
           // return instance.ID==this.pi.ID && this.pi.IsLocked==false
        },
        gradebuttonclass(instance){
            if(this.isGradable(instance)){
                return "gradebutton"
            }else{
                return "gradebuttonlocked"
            }
        },
        
        showCheck(componentId, instance){
            var show = false;
            if(this.usingChecks){
                var g = this.getGrade(componentId,instance)
                //console.log('show ' + componentId + ' ' + g)
                show = (g =='1' || g=='P')
                //console.log(show)
            }
            
           return  show
        },
        getGrade(componentId, instance){
            //console.log('get grade ' + instance.ID + " " + instance.ProcedureStepComponentGrade_InstructorList.length)
            var grade;
            instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
              //  console.log(comp.RelatedID)
                if(comp.RelatedID==componentId){
                    grade=comp.Grade
                }
            }
            //console.log('got it ' + componentId + ' ' + grade)
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
            if(this.usingChecks){
                if(grade=='0'){grade=""}
            }
            //console.log(grade)
            return grade
        },
        getCheckOffData(componentId, instance){
            var data;
            instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
              //  console.log(comp.RelatedID)
                if(comp.RelatedID==componentId){
                    data=comp.checkOffData
                }
            }
            return data
        },
        
        async clockOutStudent(){
            let req = {
                    locationId:this.pi.LocationID,
                    instructorId:this.pi.InstructorId,
                    personID:this.pi.StudentID
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockoutstudent')
                //handle response
                if(response.Successful){
                    this.$emit('gotohome')
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
        },
        
        FixSubmitText(text){

            

            var out= text.replace('Passed', this.PassedText)
            out  = out.replace('Failed',this.FailedText)
            out = out.replace('Critical Miss', this.CriticalMissText)
            out = out.replace('CRITICAL MISS', this.CriticalMissText)
            out = out.replace('critical miss(es)', this.CriticalMissText)
            return out
        },
        
          async home(){
            if(this.inTimeUpdate){
                this.responseMessage="Please Save or Cancel your Time Update before closing"
                return
            }
            this.$emit('gotohome')
            
          },
          
          
      
      },
      mounted(){
        this.multColumnOverride= localStorage.multColumnOverride
        this.zoom=this.multiColumnCount
  
      }
  }
  </script>
  
  <style scoped>
@media print {


}
::-webkit-scrollbar{

-webkit-appearance: none;
width: 7px;

}

::-webkit-scrollbar-thumb {

border-radius: 4px;
background-color: rgba(0,0,0,.5); 
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

  .gradecheckimage{
    height:20px;
  }
  .gradeboxlabel{
    margin:0px;
    padding:0px;
  }
  .gradeDiv{
    margin:20px;
    display:grid;
    grid-template-rows: auto auto auto 100px;
  }
  .topDiv{
    display:grid;
    grid-template-columns: 320px auto 180px;
    
  }
  .gradesteps{
        padding:5px;
        background-color: var(--frame-color);
    }
  .commentlabel2{
    margin:10px;
    padding: 0px 0px 0px 0px;
    /* height:90px; */
    /* overflow-x:hidden;
    overflow-y:scroll; */
  }
  .leftborder{
    border-left:1px solid gray;
    margin-bottom:10px;
  }
  .rightborder{
    border-right:1px solid gray;
    margin-bottom:10px;
  }
  .total{
    text-align: left;
    font-size:larger;
    font-weight:bold;
  }
  .studentinfo{

    
  }
  .comments{
    background-color:var(--frame-color);
    margin:0px 10px 10px 10px;
    
  }
  .studentinfoandpic{
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin:0px 0px 0px 0px;
  }
.img{
    height:110px;
}
  .gradebutton{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    border:3px solid green;
    font-size:larger;
    text-align: center;
    text-align-last:center;
    padding:3px;
  }
  .gradebuttonlocked{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    color:black;
    border:1px solid gray;
    font-size:larger;
    text-align: center;
    padding:3px;
  }
  .gradeinner{
    margin:0px;
    padding:0px;
    text-align: center;
    text-align-last:center;
  }
    .headerlabel{
        /* font-weight:bold; */
        margin:0px;
        /* font-size:larger; */
    }
    .headerlabelfailed{
        margin:0px;
        color:red;
    }
    .steplabel{
        font-size:larger;
        margin:0px 0px 0px 0px ;
        padding: 5px 0px 5px 0px;
        font-weight:bold;
        background-color:#AFBCCE;
        width:100%;
    }
    .componentlabel{
        margin:10px 0px 0px 10px;
    }
    
    .studentname{
        margin:0px 0px 0px 0px;
        /* margin:5px 1px 2px 1px; */
        color:var(--text-color);
        font-size:smaller;
    }
    .studentinfolabel{
        margin:0px 0px 0px 0px;
        /* margin:5px 1px 2px 1px; */
        color:var(--text-color);
        font-size:smaller;
        max-width: 200px;
    }
    .redlabel{
        color:red;
        margin:5px 2px 10px 2px;
        font-size:smaller;
    }
    .larger{
        font-size:larger;
        margin-bottom:7px;
    }
    .step{
    
   }
  .component{
    border-bottom: 1px solid lightgray;
    margin-bottom:0px;
    /* margin-bottom:5px; */
    
  }
  .componentdiv{
    display:grid;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{

  }
  .graderow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px repeat(12,60px);
  }
  .graderowmulti{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px; 
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px 60px;
  }


  .headerrow{
    text-align:center;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    display:grid;
    grid-template-columns: 250px repeat(12,60px);
  }
  .widebutton{

    border: none;
    border-radius:5px;
    width:180px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
    text-decoration: none;

  }
  .narrowbutton{

    border: none;
    border-radius:5px;
    width:50px;
    height:30px;
    padding:0px;
    font-size: medium;
    margin:5px;
    text-decoration: none;

}
.recordbutton{

border: none;
border-radius:5px;
width:70px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbutton{

border: none;
border-radius:5px;
width:80px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbuttonwide{
    border: none;
border-radius:5px;
width:120px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonprof{
    border: none;
border-radius:5px;
width:195px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.filterfield{
    height:30px;
    margin:5px;
}
.filterfieldshort{
    height:30px;
    margin:5px;
    width:60px;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
}
.finishbutton{

border: none;
border-radius:5px;
width:150px;
height:40px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.btwdropdown{
    height:35px;
    margin:5px;
    font:inherit;
}


.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}
.sig{
    height:25px;
}
.manualTimeLabel{
    font-size:medium;
    margin:7px 0px 0px 0px;
    color:var(--text-color);
}
.manualTimeLabel2{
    font-size:medium;
    margin:12px 0px 0px 0px;
    color:var(--text-color);
}
.time24{
    margin:5px 10px 10px 10px;
}
.reportframe{
    padding:20px;
}
  </style>

/* if(componentId==5){
    this.selectedGradeType_Files='check'
    var g = this.getGrade(componentId,instance)
    if(g=='P'){
        this.setGrade(null)
    }else{
        this.setGrade('P')
    }
    
    return;
}

switch (componentId) {
    case 1:
        this.selectedGradeType='1to5'
        break;
    case 2:
        this.selectedGradeType='1to4'
        break;
    case 3:
        this.selectedGradeType='pf'
        break;
    case 4:
        this.selectedGradeType='enter'
        break;
    
        

    default:
        break;
} */

/* let req = {
    ProcedureInstance:this.selectedInstance,
    autoStartStopTime:!this.isManualTime
}
 this.$store.dispatch('beforeAPI');
var response = await this.callAPI(req,'canceltime')
//handle response
if(response.Successful){
    this.$emit('gotohome')           
}else{
    this.responseMessage = response.Message
}
this.pleaseWait=false;  */